import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild, Input, OnChanges } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SubFolderResult } from '../../models/subfolder-result';
import { PreferencesService } from 'src/app/shared/service/preferences.service';

@Component({
  selector: 'app-subfolders-table-view',
  templateUrl: './subfolders-table-view.component.html',
  styleUrls: ['./subfolders-table-view.component.scss'],
})
export class SubfoldersTableViewComponent implements OnChanges {
  @Input() dataSource: SubFolderResult;
  @Input() hasResults: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;
  totalSubfoldersNumber: number;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 100];
  displayedColumns: string[] = [
    'Statut',
    'Nom de la démarche',
    'Concerné',
    'Numéro',
    'date de transmission',
    'dernière mise à jour',
  ];
  showFirstLastButtons = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private preferencesService: PreferencesService
  ) {
    this.pageSize = +this.preferencesService.getUserPagination();
  }

  ngOnChanges(): void {
    if (this.dataSource) {
      this.totalSubfoldersNumber = this.dataSource.total;
      this.data = new MatTableDataSource(this.dataSource.subfolders);
    }
  }

  handlePageEvent(event: PageEvent): void {
    this.totalSubfoldersNumber = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        pageNumber: this.pageIndex,
        pageSize: this.pageSize,
      },
      queryParamsHandling: 'merge',
    });
    window.scrollTo(0, 0);
  }
}
