import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Process } from 'src/app/shared/models/process';
import { ProcessService } from 'src/app/shared/service/process.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { dynamicSort } from 'src/app/shared/utils/utility-functions';
import { ApplicationException } from 'src/app/shared/exceptions/application.exception';

@Injectable({
  providedIn: 'root',
})
export class FetchProcessService {
  private processSubject: BehaviorSubject<Process[]> = new BehaviorSubject(
    null
  );
  processes: Observable<Process[]> = this.processSubject.asObservable();
  userProcessPermission: string[] = [];

  constructor(
    private processService: ProcessService,
    private oauthService: OAuthService
  ) {
    this.getUserProcessPermission();
  }
  /**
   * Retrieves current user organization.
   */
  private getUserProcessPermission(): void {
    const claims: any = this.oauthService.getIdentityClaims();
    if (claims.user_type === 'AGT') {
      this.userProcessPermission = claims.process_permission;
    } else {
      this.userProcessPermission = null;
    }
  }

  fetchProcessForSubfolderFilter() {
    this.processService
      .getProcessPermissionDetails(this.userProcessPermission)
      .subscribe({
        next: (processes: Process[]) => {
          this.processSubject.next(processes.sort(dynamicSort('code')));
        },
        error: (error) => {
          this.processSubject.next(null);
          throw new ApplicationException(error);
        },
      });
  }
}
