import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SubfoldersDetailsUpdateStatusComponent } from '../subfolders-details-update-status-component/subfolders-details-update-status-component.component';

@Component({
  selector: 'app-send-message-dialog-box',
  templateUrl: './send-message-dialog-box.component.html',
  styleUrls: [
    './send-message-dialog-box.component.scss',
    '../confirmation-dialog-box/confirmation-dialog-box.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SendMessageDialogBoxComponent {
  public message = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<SubfoldersDetailsUpdateStatusComponent>
  ) {}
}
