<div
  tabindex="0"
  role="alert"
  aria-labelledby="box-title"
  class="confirmation-dialog-box-content"
>
  <div class="title-area" mat-dialog-title>
    <div class="title-right-side">
      <div>
        <i class="fas fa-exclamation-triangle"></i>
      </div>
      <h5>
        <div id="box-title" class="title">{{ 'labels.subfolder.confirmationDownloadDialogBox.title' | translate }}</div>
      </h5>
    </div>
    <i
      aria-label="annuler la mise à jour"
      class="fas fa-times"
      mat-dialog-close="cancel"
    ></i>
  </div>
  <mat-dialog-content>
    <div
      tabindex="0"
      aria-label="résumé du mise à jour du statut"
      class="changing-status-summary"
    >
      {{ "labels.subfolder.confirmationReceptionDialogBox.from" | translate }}
      <div
        tabindex="0"
        aria-label="statut actuel"
        class="old-status"
        *ngIf="data && data.subfolder && data.subfolder.status"
      >
        <i
          class="{{
            'subfolder.statusIcon.' + data.subfolder.status | translate
          }}"
        ></i>
        <div tabindex="0" class="status-name">
          {{ "subfolder.status." + data.subfolder.status | translate }}
        </div>
      </div>
      {{ "labels.subfolder.confirmationReceptionDialogBox.to" | translate }}
      <div tabindex="0" aria-label="statut mise à jour" class="new-status">
        <i
          class="{{ 'subfolder.statusIcon.4' | translate }}"
        ></i>
        <div tabindex="0" class="status-name">
          {{ "subfolder.status.4" | translate }}
        </div>
      </div>
    </div>
    <div tabindex="0" class="message">{{ 'labels.subfolder.confirmationDownloadDialogBox.messageOfReception' | translate }}</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      aria-label="annuler la mise à jour"
      role="button"
      class="cancel-button"
      mat-button
      mat-dialog-close
      mat-dialog-close="cancel"
    >
      {{ 'labels.subfolder.confirmationDownloadDialogBox.cancelButton' | translate }}
    </button>
    <button
      aria-label="confirmer la mise à jour"
      role="button"
      class="confirm-button"
      mat-button
      mat-dialog-close
      mat-dialog-close="confirm"
    >
      {{ 'labels.subfolder.confirmationDownloadDialogBox.confirmButtonOfReception' | translate }}
    </button>
  </mat-dialog-actions>
</div>
