<section class="banner container-fluid py-4">
  <div class="row justify-content-between">
    <div class="ml-4" id="CONSULT-TD-RETOUR_RECH">
      <a
        tabindex="0"
        aria-label="retour à la liste des télédossiers"
        title="retour"
        (click)="goBack()"
      >
        <i class="fa fa-chevron-left"></i>
        {{ "labels.subfolder.details.header.goBack" | translate }}
      </a>
    </div>
    <div
      tabindex="0"
      aria-label="numéro du dossier"
      class="mr-4 ml-4 mt-4 mt-md-0"
      id="CONSULT-TD-NUM_TD"
    >
      <span class="label">
        {{ "labels.subfolder.details.header.subfolderNumber" | translate }}
      </span>
      <span tabindex="0" class="font-weight-bold">
        &nbsp;{{
          subfolder
            ? subfoldersService.formatSubfolderNumber(subfolder.subfolderNumber)
            : ""
        }}
      </span>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12 col-md-3 col-lg-2 mb-4" id="CONSULT-TD-STAT_TD">
      <mat-card
        appearance="outlined"
        tabindex="0"
        aria-label="statut du dossier"
        class="
          status-card
          mx-auto
          py-2
          d-flex
          flex-column
          align-items-center
          justify-content-center
        "
      >
        <div
          class="d-flex flex-column align-items-center"
          *ngIf="!disableUpdateStatus"
        >
          <i
            id="ICONE_STAT_TD"
            class="{{
              'subfolder.statusIcon.' + subfolder?.status | translate
            }} fa-2x"
          >
          </i>
          <div tabindex="0" class="text-center mt-2">
            {{ "subfolder.status." + subfolder?.status | translate }}
          </div>
        </div>
        <div
          class="d-flex flex-column align-items-center"
          *ngIf="disableUpdateStatus"
        >
          <i class="fas fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
        </div>
      </mat-card>
    </div>

    <div class="col-12 col-md-8 col-lg-7 infos">
      <p id="CONSULT-TD-NOM_DEM">
        <span tabindex="0">{{
          "labels.subfolder.details.header.process" | translate
        }}</span
        ><span tabindex="0" class="process-name font-weight-bold">{{
          subfolder?.process.name
        }}</span>
      </p>
      <p id="CONSULT-TD-IDENT_DEM">
        <span tabindex="0">{{
          "labels.subfolder.details.header.requester" | translate
        }}</span>
        <span
          tabindex="0"
          class="text-uppercase"
          id="consult-td-logo_format_pj"
        >
          {{ subfolder?.applicant.lastName }}
        </span>
        <span tabindex="0"> &nbsp;{{ subfolder?.applicant.firstName }}</span>
      </p>
      <p id="CONSULT-TD-MAIL_DEM">
        <span tabindex="0">{{
          "labels.subfolder.details.header.requester.email" | translate
        }}</span
        ><span tabindex="0">{{ subfolder?.applicant.email }}</span>
      </p>

      <div class="container-fluid mt-5">
        <div class="row justify-content-between">
          <p id="CONSULT-TD-DATE_SOUM" *ngIf="subfolder?.transmissionTimestamp">
            <span tabindex="0">{{
              "labels.subfolder.details.header.submitDate" | translate
            }}</span
            ><span tabindex="0"
              >{{ subfolder.transmissionTimestamp | date: "dd/MM/yyyy" }}
              {{ subfolder.transmissionTimestamp | date: "HH:mm" }}</span
            >
          </p>
          <p id="CONSULT-TD-DATE_MODIF" *ngIf="subfolder?.updateTimestamp">
            <span tabindex="0">{{
              "labels.subfolder.details.header.updateDate" | translate
            }}</span
            ><span tabindex="0">
              {{ subfolder.updateTimestamp | date: "dd/MM/yyyy" }}
              {{ subfolder.updateTimestamp | date: "HH:mm" }}</span
            >
          </p>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-3">
      <div class="d-flex flex-column align-items-center">
        <mat-form-field
          id="CONSULT-TD-MODIF_STAT"
          appearance="fill"
          *ngIf="+subfolder?.status < 7"
        >
          <mat-label tabindex="0">{{
            "labels.subfolder.details.header.modifyStatus" | translate
          }}</mat-label>
          <div
            class="tooltip-info"
            matTooltip=" {{
              'labels.subfolder.details.header.disabledUpdatingStatus'
                | translate
            }}"
            matTooltipPosition="left"
            [matTooltipDisabled]="userHasRoleSubfolderManagement()"
          >
            <mat-select
              [disabled]="
                !userHasRoleSubfolderManagement() || disableUpdateStatus
              "
              aria-label="Modifier le status"
            >
              <mat-option
                *ngFor="let status of statuses"
                (click)="openStatusMessageDialog(status.id)"
              >
                {{ status.statusName }}
              </mat-option>
            </mat-select>
          </div>
        </mat-form-field>
        <div
          class="tooltip-info"
          matTooltip=" {{
            'labels.subfolder.details.header.disabledSendingMessage' | translate
          }}"
          matTooltipPosition="left"
          [matTooltipDisabled]="subfolder?.process.messageToTransmitter"
        >
          <button
            aria-label="envoyer un message"
            id="CONSULT-TD-ENV_MSG"
            role="button"
            (click)="openSendMessageDialog()"
            class="sendMessage main-button"
            mat-stroked-button
            [disabled]="
              !userHasRoleSubfolderManagement() ||
              !subfolder?.process.messageToTransmitter
            "
          >
            <i class="fa fa-envelope fa-lg" aria-hidden="true"></i
            ><span class="font-weight-bold">
              {{
                "labels.subfolder.details.header.sendMessage" | translate
              }}</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
