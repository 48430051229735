import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Attachment } from 'src/app/shared/models/attachment';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subfolder } from 'src/app/shared/models/subfolder';
import { ConfirmationDownloadDialogBoxComponent } from '../../../modals/confirmation-download-box/confirmation-download-box.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-subfolders-details-attachment',
  templateUrl: './subfolders-details-attachment.component.html',
  styleUrls: ['./subfolders-details-attachment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubfoldersDetailsAttachmentComponent {
  @Input()
  subfolder: Subfolder;

  @Output()
  downloadFile: EventEmitter<Attachment> = new EventEmitter();

  @Output()
  downloadAllFiles: EventEmitter<void> = new EventEmitter();

  @Output()
  uploadFiles: EventEmitter<File[]> = new EventEmitter();

  isUploading = false;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private oauthService: OAuthService
  ) {}

  downloadAll(): void {
    if (Number(this.subfolder.status) > 3) {
      this.downloadAllFiles.emit();
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDownloadDialogBoxComponent, {
      autoFocus: false,
      data: {
        subfolder: this.subfolder,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirm') {
        this.downloadAllFiles.emit();
      }
    });
  }

  openUploadZone(): void {
    this.isUploading = true;
  }

  cancelUpload(): void {
    this.isUploading = false;
  }

  upload(files: File[]): void {
    this.isUploading = false;
    this.uploadFiles.emit(files);
  }

  /**
   * Disables button according to button action.
   *
   * @param isUpload - Is button action for uploading
   * @returns a boolean according to button action
   */
  public disableButton(isUpload: boolean): boolean {
    if (!isUpload && +this.subfolder?.purgedBinary) {
      return true;
    } else {
      return false;
    }
  }

  isAdmNat(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims && claims.user_type === 'ADM_NAT';
  }

  public userHasRoleSubfolderManagement(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) {
      return false;
    }
    return claims.groups.indexOf('GestionTD') !== -1;
  }

  getPurgeDate(): string {
    return formatDate(this.subfolder?.purgeDateTime, 'dd/MM/yyyy', 'fr-FR');
  }

  /**
   * Displays upload button or not.
   *
   * @returns a boolean to display upload button or not
   */
  public displayUploadButton(): boolean {
    if (this.subfolder?.process.attachmentsFormatsEnabled) {
      this.subfolder?.process.attachmentsFormatsEnabled.forEach((element) => {
        if (element === '')
          delete this.subfolder?.process.attachmentsFormatsEnabled;
      });
    }
    if (
      !this.disableButton(true) &&
      this.subfolder?.process.attachmentsFormatsEnabled !== undefined &&
      this.subfolder?.process.attachmentsFormatsEnabled.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  public isUploadAllowedByProcess(): boolean {
    const siAttachmentStatus = this.subfolder?.process.siAttachmentStatus;
    const authorizedUploadWithoutStatus =
      this.subfolder?.process.authorizedUploadWithoutStatus;

    return (
      this.subfolder?.process.attachmentsFormatsEnabled !== undefined &&
      this.subfolder?.process.attachmentsFormatsEnabled.length > 0 &&
      siAttachmentStatus &&
      authorizedUploadWithoutStatus
    );
  }
}
