<app-subfolders-details-banner
  [subfolder]="subfolder"
  (sendMessage)="sendMessage($event)"
  [disableUpdateStatus]="disableUpdateStatus"
  (updateStatus)="updateStatus($event)"
  (uploadFilesEventFromUpdatingDialogBox)="uploadFiles($event)"
  *ngIf="!error"
>
</app-subfolders-details-banner>
<app-subfolders-details-banner-error
  *ngIf="error"
></app-subfolders-details-banner-error>

<div class="p-4" *ngIf="!error">
  <app-subfolders-details-attachment
    [subfolder]="subfolder"
    (downloadFile)="downloadFile($event)"
    (downloadAllFiles)="downloadAllFiles()"
    (uploadFiles)="uploadFiles($event)"
  ></app-subfolders-details-attachment>
</div>

<div class="p-4" *ngIf="!error">
  <app-subfolders-details-comment
    (addComment)="addComment($event)"
  ></app-subfolders-details-comment>
</div>

<div class="p-4" *ngIf="!error">
  <app-subfolders-details-history
    *ngIf="subfolderHisotires?.length > 0"
    [history]="subfolderHisotires"
  ></app-subfolders-details-history>
</div>
