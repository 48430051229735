export class SubfoldersFilters {
  status?: string;
  processes?: string;
  startSubmittedDate?: string;
  endSubmittedDate?: string;
  requester?: string;
  number?: number;
  pageNumber?: number;
  pageSize?: number;
  searchedSubfolder?: string;
  sortAttribute?: string;
  direction?: string;
}
