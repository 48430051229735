<section
  id="ZONE_HIST"
  tabindex="0"
  aria-label="section historique"
  class="section__history"
>
  <mat-card appearance="outlined" class="rf-container-fluid p-4 pb-5">
    <h2 id="CONSULT-TD-LIBELLE-HISTORIQUE">
      <mat-card-title class="bold" id="history-title">{{
        "labels.subfolder.details.history.title" | translate | uppercase
      }}</mat-card-title>
    </h2>
    <mat-card-content class="history rf-grid-row">
      <ng-template [ngIf]="history && history.length > 0">
        <ng-container
          *ngFor="let dateTime of historyDate | keyvalue: originalOrder"
        >
          <div class="lastActivity-wrapper">
            <div id="CONSULT-TD-MOIS_TRAIT" tabindex="0" class="lastActivity">
              {{ dateTime.key | date: "MMMM yyyy":"":"fr_FR" | titlecase }}
            </div>
          </div>
          <div class="history-wrapper rf-col-12">
            <mat-card
              appearance="outlined"
              role="list"
              class="rf-grid-row"
              *ngFor="let h of dateTime.value"
            >
              <div class="mat-card-history-content">
                <div id="CONSULT-TD-DATE_ACTION" class="date-content">
                  <div tabindex="0" aria-label="date" class="date">
                    <span tabindex="0" class="dayMonth">{{
                      h.updateTimestamp
                        | date: "EEEE dd/MM":"":"fr_FR"
                        | titlecase
                    }}</span>
                    <span> - </span>
                    <span tabindex="0" class="time"
                      >{{ h.updateTimestamp | date: "HH:mm" }}
                    </span>
                  </div>
                </div>

                <div role="listitem" class="content rf-col-xs-12 rf-col-md-10">
                  <div [ngSwitch]="h.actionType" class="title">
                    <ng-template [ngIf]="checkAuthorDisplay(h)">
                      <span
                        id="CONSULT-TD-IDENT_AGENT_TRAIT"
                        tabindex="0"
                        class="user bold"
                        >{{ h.author }}</span
                      >
                    </ng-template>
                    <span
                      *ngIf="h.subfolderCurrentStatus === 1"
                      tabindex="0"
                      class="user bold"
                      >{{
                        "labels.subfolder.details.history.portalAuthor"
                          | translate
                      }}</span
                    >
                    <ng-template [ngSwitchCase]="actionTypeHtml.STATUS_UPDATE">
                      <ng-template [ngIf]="checkMultipleActions(h)">
                        <span
                          id="CONSULT-TD-ACTION_HISTORISE"
                          tabindex="0"
                          class="rf-col-12"
                        >
                          {{
                            "labels.subfolder.details.history.multipleActions"
                              | translate
                          }}
                        </span>
                      </ng-template>
                      <ng-template
                        [ngIf]="
                          !checkMultipleActions(h) &&
                          h.subfolderCurrentStatus != 0 &&
                          h.subfolderNewStatus != 0
                        "
                      >
                        <ng-template [ngIf]="h.subfolderCurrentStatus > 1">
                          <span
                            id="CONSULT-TD-ACTION_HISTORISE"
                            *ngIf="h.subfolderNewStatus < 8"
                            tabindex="0"
                          >
                            {{
                              "labels.subfolder.details.history.changedStatus"
                                | translate
                            }}</span
                          >
                        </ng-template>
                        <span
                          id="CONSULT-TD-ACTION_HISTORISE"
                          *ngIf="h.subfolderCurrentStatus === 1"
                          tabindex="0"
                        >
                          {{
                            "labels.subfolder.details.history.portalReception"
                              | translate
                          }}</span
                        >
                        <span
                          id="CONSULT-TD-ACTION_HISTORISE"
                          *ngIf="h.subfolderNewStatus === 8"
                          tabindex="0"
                        >
                          {{
                            "labels.subfolder.details.history.closedSubfolder"
                              | translate
                          }}
                        </span>
                        <p
                          id="CONSULT-TD-DETAIL_ACTION"
                          *ngIf="1 < h.subfolderCurrentStatus"
                          tabindex="0"
                        >
                          {{
                            "subfolder.status." + h.subfolderCurrentStatus
                              | translate
                          }}
                          >
                          {{
                            "subfolder.status." + h.subfolderNewStatus
                              | translate
                          }}
                        </p>
                      </ng-template>
                      <ng-template
                        [ngIf]="
                          (!checkMultipleActions(h) &&
                            h.subfolderCurrentStatus == 0) ||
                          h.subfolderNewStatus == 0
                        "
                      >
                        <span
                          id="CONSULT-TD-ACTION_HISTORISE"
                          *ngIf="h.subfolderNewStatus < 8"
                          tabindex="0"
                        >
                          {{
                            "labels.subfolder.details.history.changedStatusAtzero"
                              | translate
                          }}</span
                        >
                      </ng-template>
                    </ng-template>
                    <ng-template [ngSwitchCase]="actionTypeHtml.COMM_ADD">
                      <span id="CONSULT-TD-ACTION_HISTORISE" tabindex="0">
                        {{
                          "labels.subfolder.details.history.addedComment"
                            | translate
                        }}</span
                      >
                    </ng-template>
                    <ng-template
                      [ngSwitchCase]="actionTypeHtml.SENDING_MESSAGE"
                    >
                      <span id="CONSULT-TD-ACTION_HISTORISE" tabindex="0">
                        {{
                          "labels.subfolder.details.history.sentMessage"
                            | translate
                        }}</span
                      >
                    </ng-template>
                    <ng-template [ngSwitchCase]="actionTypeHtml.ATTACH_DL">
                      <span id="CONSULT-TD-ACTION_HISTORISE" tabindex="0">
                        {{
                          "labels.subfolder.details.history.attachmentDownloaded"
                            | translate
                        }}
                      </span>
                    </ng-template>
                    <ng-template [ngSwitchCase]="actionTypeHtml.ATTACH_DEPOSIT">
                      <ng-template [ngIf]="checkMultipleActions(h)">
                        <span
                          id="CONSULT-TD-ACTION_HISTORISE"
                          tabindex="0"
                          class="rf-col-12"
                        >
                          {{
                            "labels.subfolder.details.history.multipleActions"
                              | translate
                          }}
                        </span>
                      </ng-template>
                      <ng-template [ngIf]="!checkMultipleActions(h)">
                        <ng-template
                          [ngIf]="h.attachments && h.attachments.length === 1"
                        >
                          <span tabindex="0">
                            {{
                              "labels.subfolder.details.history.attachmentUploaded"
                                | translate
                            }}
                          </span>
                        </ng-template>
                        <ng-template
                          [ngIf]="h.attachments && h.attachments.length > 1"
                        >
                          <span tabindex="0">
                            {{
                              "labels.subfolder.details.history.attachmentsUploaded"
                                | translate
                            }}
                          </span>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                    <ng-template [ngSwitchCase]="actionTypeHtml.ATTACH_DL_ALL">
                      <span id="CONSULT-TD-ACTION_HISTORISE" tabindex="0">
                        {{
                          "labels.subfolder.details.history.allAttachmentsDownloaded"
                            | translate
                        }}
                      </span>
                    </ng-template>
                  </div>
                  <div [ngSwitch]="h.actionType" class="action rf-grid-row">
                    <ng-template [ngSwitchCase]="actionTypeHtml.STATUS_UPDATE">
                      <ng-template [ngIf]="checkMultipleActions(h)">
                        <span
                          id="CONSULT-TD-ACTION_HISTORISE"
                          tabindex="0"
                          class="bold rf-col-12"
                        >
                          {{
                            "labels.subfolder.details.history.multipleActionsChangedStatus"
                              | translate
                          }}
                        </span>
                        <p *ngIf="h.subfolderCurrentStatus > 1" tabindex="0">
                          {{
                            "subfolder.status." + h.subfolderCurrentStatus
                              | translate
                          }}
                          >
                          {{
                            "subfolder.status." + h.subfolderNewStatus
                              | translate
                          }}
                        </p>
                      </ng-template>
                      <ng-template
                        [ngIf]="h.message && h.message.trim().length > 0"
                      >
                        <div id="CONSULT-TD-COMM" style="display: contents">
                          <span tabindex="0" class="action bold rf-col-12">{{
                            "labels.subfolder.details.history.commentLabel"
                              | translate
                          }}</span>
                          <span class="rf-col-12">{{ h.message }}</span>
                        </div>
                      </ng-template>
                      <ng-template
                        [ngIf]="h.comment && h.comment.trim().length > 0"
                      >
                        <span tabindex="0" class="action bold rf-col-12">
                          {{
                            "labels.subfolder.details.history.internCommentLabel"
                              | translate
                          }}
                        </span>
                        <span class="rf-col-12">{{ h.comment }} </span>
                      </ng-template>
                    </ng-template>
                    <ng-template [ngSwitchCase]="actionTypeHtml.COMM_ADD">
                      <span tabindex="0" class="bold rf-col-12">{{
                        "labels.subfolder.details.history.internCommentLabel"
                          | translate
                      }}</span>
                      <span tabindex="0" class="rf-col-12">{{
                        h.comment
                      }}</span>
                    </ng-template>
                    <ng-template
                      [ngSwitchCase]="actionTypeHtml.SENDING_MESSAGE"
                    >
                      <div id="CONSULT-TD-COMM" style="display: contents">
                        <span tabindex="0" class="bold rf-col-12">{{
                          "labels.subfolder.details.history.commentLabel"
                            | translate
                        }}</span>
                        <span tabindex="0" class="rf-col-12">{{
                          h.message
                        }}</span>
                      </div>
                    </ng-template>

                    <ng-template [ngSwitchCase]="actionTypeHtml.ATTACH_DL">
                      <span tabindex="0" *ngIf="h.message">{{
                        h.message
                      }}</span>
                    </ng-template>
                    <ng-template [ngSwitchCase]="actionTypeHtml.ATTACH_DEPOSIT">
                      <ng-template [ngIf]="checkMultipleActions(h)">
                        <span tabindex="0" class="bold rf-col-12">
                          {{
                            "labels.subfolder.details.history.multipleActionsAttachmentUploaded"
                              | translate
                          }}
                        </span>
                      </ng-template>
                      <ul tabindex="0" aria-label="liste des attachements">
                        <li tabindex="0" *ngFor="let a of h.attachments">
                          {{ a.fileName }}
                        </li>
                      </ul>
                      <ng-template
                        [ngIf]="
                          checkMultipleActions(h) && h.subfolderNewStatus !== 0
                        "
                      >
                        <span tabindex="0" class="bold rf-col-12">
                          {{
                            "labels.subfolder.details.history.multipleActionsChangedStatus"
                              | translate
                          }}
                        </span>
                        <p *ngIf="h.subfolderCurrentStatus > 1" tabindex="0">
                          {{
                            "subfolder.status." + h.subfolderCurrentStatus
                              | translate
                          }}
                          >
                          {{
                            "subfolder.status." + h.subfolderNewStatus
                              | translate
                          }}
                        </p>
                      </ng-template>
                      <ng-template
                        [ngIf]="h.message && h.message.trim().length > 0"
                      >
                        <div id="CONSULT-TD-COMM" style="display: contents">
                          <span tabindex="0" class="action bold rf-col-12">{{
                            "labels.subfolder.details.history.commentLabel"
                              | translate
                          }}</span>
                          <span class="rf-col-12">{{ h.message }}</span>
                        </div>
                      </ng-template>
                      <ng-template
                        [ngIf]="h.comment && h.comment.trim().length > 0"
                      >
                        <span tabindex="0" class="action bold rf-col-12">
                          {{
                            "labels.subfolder.details.history.internCommentLabel"
                              | translate
                          }}
                        </span>
                        <span class="rf-col-12">{{ h.comment }} </span>
                      </ng-template>
                    </ng-template>
                  </div>
                  <div
                    *ngIf="
                      (h.actionType === actionTypeHtml.STATUS_UPDATE ||
                        h.actionType === actionTypeHtml.ATTACH_DEPOSIT) &&
                      h.subfolderCurrentStatus !== 1
                    "
                    class="transmitted rf-grid-row"
                    id="CONSULT-TD-ENVOI_DEMANDEUR"
                  >
                    <span
                      id="CONSULT-TD-ENVOI_DEMANDEUR"
                      tabindex="0"
                      class="transmitted-label rf-col-12"
                      >{{
                        "labels.subfolder.details.history.commentSent"
                          | translate
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </ng-container>
      </ng-template>
    </mat-card-content>
  </mat-card>
</section>
