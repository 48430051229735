import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Attachment } from 'src/app/shared/models/attachment';

@Component({
  selector: 'app-subfolders-details-attachment-list',
  templateUrl: './subfolders-details-attachment-list.component.html',
  styleUrls: ['./subfolders-details-attachment-list.component.scss'],
})
export class SubfoldersDetailsAttachmentListComponent {
  @Input()
  attachments: Array<Attachment>;

  @Input()
  isAdminMode: boolean;

  @Input()
  shouldDisableDownload: boolean;

  @Output()
  downloadFile: EventEmitter<Attachment> = new EventEmitter();

  constructor(private oauthService: OAuthService) {}

  userHasRoleSubfolderManagement(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();

    if (!claims) {
      return false;
    }

    return claims.groups.indexOf('GestionTD') !== -1;
  }

  isAdmNat(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims && claims.user_type === 'ADM_NAT';
  }
}
