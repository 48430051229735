import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { History } from 'src/app/shared/models/history';
import { Subfolder, SubfolderIn } from 'src/app/shared/models/subfolder';
import { environment_endpoints } from 'src/environments/environment';
import { SubFolderResult } from '../models/subfolder-result';
import { SubfoldersFilters } from '../models/subfoldersFilters';

@Injectable({
  providedIn: 'root',
})
export class SubfoldersHttpService {
  private token = '';
  private headers: HttpHeaders;
  private urlSubfolders = environment_endpoints.api.subfolders;

  constructor(private http: HttpClient, private oauthService: OAuthService) {
    this.token = this.oauthService.getIdToken();
    this.headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + this.token)
      .append('AccessToken', this.oauthService.getAccessToken());
  }

  public getSubfolderById(subfolderId: number): Observable<Subfolder> {
    return this.http.get<Subfolder>(`${this.urlSubfolders}/${subfolderId}`, {
      headers: this.headers,
    });
  }

  public updateStatus(
    subfolderId: number,
    subfolderIn: SubfolderIn,
    files: File[]
  ): Observable<Subfolder | HttpErrorResponse> {
    const body: FormData = new FormData();
    body.append(
      'subfolderIn',
      new Blob([JSON.stringify(subfolderIn)], { type: 'application/json' })
    );
    if (files) {
      files.forEach((element) => {
        body.append('files', element);
      });
    }
    return this.http.patch<Subfolder>(
      `${this.urlSubfolders}/${subfolderId}`,
      body,
      {
        headers: this.headers,
      }
    );
  }

  /**
   * Mets à jour plusieurs sous dossiers.
   *
   * @param newStatus - Nouveau statut à attribuer aux TDs
   * @param updatingSubfoldersIds - Les numéros de TDs à mettre à jour
   * @param updatingComment - Le commentaire associé au changement de statut
   * @param updatingAuthor - L'auteur du changement de statut
   * @param currentFilters - Les filtres actuels des TDs
   * @param currentStatus - Le statut initial de l'ensemble des TDs à mettre à jour
   * @returns - Un observable qui renvoie le résultat de la requête avec les nouvelles informations des TDs mises à jour
   * TODO : ajouter aussi un évènement d'historique pour chaque td maj
   */
  public updateMultipleSubfolders(
    newStatus: number,
    updatingSubfoldersIds: number[],
    updatingComment: string,
    updatingAuthor: string,
    currentFilters: SubfoldersFilters,
    currentStatus: number
  ): Observable<SubFolderResult | HttpErrorResponse> {
    currentFilters.status = newStatus.toString();
    currentFilters.pageNumber = 0;
    const map = {
      status: newStatus,
      subfoldersIds: updatingSubfoldersIds,
      comment: updatingComment,
      author: updatingAuthor,
      messageTransmission: true,
      currentStatus,
    };
    return this.http.patch<any>(
      `${this.urlSubfolders}/multipleStatusesUpdate`,
      map,
      {
        headers: this.headers,
      }
    );
  }

  public downloadAttachment(
    subfolderId: number,
    history: History,
    attachmentId: string
  ): Observable<Blob> {
    return this.http.post<Blob>(
      `${this.urlSubfolders}/${subfolderId}/attachments/${attachmentId}`,
      history,
      {
        headers: this.headers,
        responseType: 'blob' as 'json',
      }
    );
  }

  /**
   * Downloads all attachments of a single subfolder.
   *
   * @param subfolderId - subfolder id
   * @param history - new history to create
   * @param includeXML - include xml files or not in downloaded attachment
   * @returns all attachments from the subfolder
   */
  public downloadAllAttachments(
    subfolderId: number,
    history: History,
    includeXML: boolean
  ): Observable<Blob> {
    return this.http.post<Blob>(
      `${this.urlSubfolders}/${subfolderId}/attachments/all`,
      history,
      {
        headers: this.headers,
        responseType: 'blob' as 'json',
        params: new HttpParams({
          fromObject: {
            includeXML: includeXML.toString(),
          },
        }),
      }
    );
  }

  /**
   * Downloads all attachments from selected subfolders.
   *
   * @param history - new history to create
   * @returns all attachments from selected subfolders
   */
  public downloadAllAttachmentsMultipleSubfolders(
    history: History,
    includeXML: boolean
  ): Observable<Blob> {
    return this.http.post<Blob>(
      `${this.urlSubfolders}/attachments/all`,
      history,
      {
        headers: this.headers,
        responseType: 'blob' as 'json',
        params: new HttpParams({
          fromObject: {
            includeXML: includeXML.toString(),
          },
        }),
      }
    );
  }

  public uploadAttachments(
    subfolderId: number,
    history: History,
    files: File[]
  ): Observable<History> {
    const body: FormData = new FormData();
    body.append(
      'history',
      new Blob([JSON.stringify(history)], { type: 'application/json' })
    );
    files.forEach((element) => {
      body.append('files', element);
    });
    return this.http.post<History>(
      `${this.urlSubfolders}/${subfolderId}/attachments/upload`,
      body,
      { headers: this.headers }
    );
  }
}
