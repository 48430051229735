<mat-card
  appearance="outlined"
  [ngClass]="{ 'updating-box': isUploadingFromUpdatingBox }"
  class="rf-container-fluid mat-elevation-z"
>
  <mat-card-content>
    <div
      id="UPLOAD-PJ-DRAGDROP-UPLOAD"
      [ngClass]="{ empty: attachments.length === 0 }"
      class="attachments-wrapper"
      (click)="fileInput.click(); $event.stopPropagation()"
      appDragDrop
      (onFileDropped)="uploadFile($event)"
    >
      <label class="hide-label" for="uploadFileId">Ajoutez des fichiers</label>
      <input
        tabindex="0"
        id="uploadFileId"
        hidden
        type="file"
        #fileInput
        (change)="uploadFile($event.target.files); $event.stopPropagation()"
      />
      <div *ngIf="attachments.length === 0" class="info-upload">
        {{ "labels.subfolder.details.attachments.infoUpload" | translate }}
      </div>
      <div
        *ngIf="attachments && attachments.length > 0"
        class="attachments rf-grid-row"
      >
        <div
          class="attachment rf-col-xs-6 rf-col-sm-4"
          *ngFor="let attachment of attachments; let i = index"
        >
          <div class="link-content rf-grid-row">
            <span class="icon rf-col-12">
              <fa-icon
                id="UPLOAD-PJ-FORMAT"
                [icon]="attachment?.mimeType | fileIcon"
              ></fa-icon>
            </span>
            <span id="UPLOAD-PJ-NAME" class="filename rf-col-12">{{
              attachment.fileName
            }}</span>
          </div>
          <span id="UPLOAD-PJ-SIZE" class="size">{{
            attachment?.size | fileSize
          }}</span>
          <button
            id="UPLOAD-PJ-BT-DELETE"
            aria-label="supprimer le fichier"
            role="button"
            class="delete-file"
            (click)="deleteAttachment(i); $event.stopPropagation()"
          >
            <i class="fas fa-trash-alt"></i>
            {{ "labels.subfolder.details.attachments.delete" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        (!isAttachmentsFormatsAccepted() || !isAttachmentSizeAccepted()) &&
        isUploadingFromUpdatingBox
      "
      id="format-error-message"
    >
      {{
        "labels.subfolder.details.attachments.errorUploadingAttachmentFormatMessagePart1"
          | translate
      }}
      {{ attachmentsFormatsEnabled }}
      {{
        "labels.subfolder.details.attachments.errorUploadingAttachmentFormatMessagePart2"
          | translate
      }}
      {{ attachmentsMaxSizeEnabled | fileSize }}
      {{
        "labels.subfolder.details.attachments.errorUploadingAttachmentFormatMessagePart3"
          | translate
      }}
    </div>
    <div class="buttons-group">
      <button
        id="UPLOAD-PJ-BT-UPLOAD"
        aria-label="confirmer le chargement des fichiers"
        [disabled]="
          !isAttachmentsFormatsAccepted() || !isAttachmentSizeAccepted()
        "
        role="button"
        class="confirm-upload-button"
        mat-flat-button
        (click)="onConfirmUploadButtonClicked()"
      >
        <i class="fas fa-file-upload"></i>
        {{ "labels.subfolder.details.attachments.confirmUpload" | translate }}
      </button>
      <button
        id="UPLOAD-PJ-BT-ANNULER"
        aria-label="annuler le chargement des fichiers"
        role="button"
        class="cancel-button"
        mat-flat-button
        (click)="
          !isUploadingFromUpdatingBox
            ? openConfirmationCancelingDialog()
            : CancelUploadButtonClickedEvent.emit()
        "
      >
        {{ "labels.subfolder.details.attachments.cancel" | translate }}
      </button>
    </div>
  </mat-card-content>
  <div
    *ngIf="
      (!isAttachmentsFormatsAccepted() || !isAttachmentSizeAccepted()) &&
      !isUploadingFromUpdatingBox
    "
    id="format-error-message"
  >
    {{
      "labels.subfolder.details.attachments.errorUploadingAttachmentFormatMessagePart1"
        | translate
    }}
    {{ attachmentsFormatsEnabled }}
    {{
      "labels.subfolder.details.attachments.errorUploadingAttachmentFormatMessagePart2"
        | translate
    }}
    {{ attachmentsMaxSizeEnabled | fileSize }}
    {{
      "labels.subfolder.details.attachments.errorUploadingAttachmentFormatMessagePart3"
        | translate
    }}
  </div>
</mat-card>
