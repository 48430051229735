<div tabindex="0" aria-label="liste des dossiers" class="list-page-container">
  <h2 class="title-no-display">{{ "labels.menu.subfolders" | translate }}</h2>
  <div *ngIf="!hasResults" class="no-results">
    {{ "labels.subfolder.listView.noResults" | translate }}
  </div>
  <ng-container *ngFor="let subfolder of dataSource.subfolders; let i = index">
    <section class="subfolder-section">
      <div
        tabindex="-1"
        *ngxPermissionsOnly="['GestionTD']"
        class="subfolder-checkbox"
      >
        <div tabindex="-1" class="checkbox-top-space"></div>
        <mat-checkbox
          id="{{ 'RECH-TD-BT-SELECT-' + i }}"
          aria-label="selectionnez ce teledossier"
          tabindex="-1"
          role="checkbox"
          aria-checked="true"
          [(ngModel)]="subfolder.isSelected"
          [(indeterminate)]="indeterminate"
          [labelPosition]="labelPosition"
          [disabled]="disabled"
          (change)="onSubfolderSelected(subfolder)"
        >
          selectionnez ce teledossier
        </mat-checkbox>
      </div>
      <section
        tabindex="0"
        aria-label="dossier avec le statut"
        class="subfolder-details"
        [ngClass]="{
          'selected-subfolder': subfolder.isSelected,
          'consultation-td-view': !userHasRoleSubfolderManagement()
        }"
      >
        <div
          id="RECH-TD-STATUT"
          class="subfolder-status-group"
          *ngIf="!subfoldersToUpdate.includes(subfolder.id)"
        >
          <div class="icon">
            <i
              class="{{
                'subfolder.statusIcon.' + subfolder.status | translate
              }}"
            ></i>
          </div>
          <div tabindex="0" class="status-name">
            {{ "subfolder.status." + subfolder.status | translate }}
          </div>
        </div>
        <div
          class="subfolder-status-group"
          *ngIf="subfoldersToUpdate.includes(subfolder.id)"
        >
          <div class="icon">
            <i class="fas fa-spinner fa-spin" aria-hidden="true"></i>
          </div>
        </div>
        <div class="middle-infos">
          <div class="requester-and-folder-info-group">
            <div
              tabindex="0"
              aria-label=" Démarche du dossier"
              class="process-requester-group"
            >
              <div
                tabindex="0"
                title="Démarche"
                class="process-type"
                id="RECH-TD-DEMARCHE"
              >
                {{ subfolder.process.name }}
              </div>
              <div tabindex="0" id="RECH-TD-DEMANDEUR">
                <span class="requester">{{
                  "labels.subfolder.listView.requester" | translate
                }}</span>

                <span class="requester-last-name">{{
                  subfolder.applicant.lastName
                }}</span
                >&nbsp;
                <span class="requester-first-name">{{
                  subfolder.applicant.firstName
                }}</span>
              </div>
            </div>
            <div
              id="RECH-TD-NUM"
              tabindex="0"
              aria-label="dossier avec le numéro suivant"
              class="subfolder-number"
              (click)="onConsultButtonClicked(subfolder)"
            >
              <span id="subfolderNumber" class="subfolder-number"
                ><span
                  tabindex="0"
                  (keydown.enter)="onConsultButtonClicked(subfolder)"
                  class="subfolder-number-link"
                  >{{
                    subfoldersService.formatSubfolderNumber(
                      subfolder.subfolderNumber
                    )
                  }}</span
                >
              </span>
            </div>
          </div>
          <div class="dates-group">
            <div class="submit-date-group" id="RECH-TD-DATE-SOUMIS">
              <span tabindex="0" class="submit-action-name"
                >{{ "labels.subfolder.listView.submitDate" | translate }}
              </span>
              <span tabindex="0" class="submit-date"
                >{{ subfolder.submitteddate
                }}{{ "labels.subfolder.listView.to" | translate
                }}{{ subfolder.submittedtime }}</span
              >
            </div>
            <div [id]="subfolder.id" style="display: contents">
              <div
                *ngIf="
                  subfolder.lastUpdateAuthor && subfolder.status > newStatus
                "
                class="update-date-group"
              >
                <div id="RECH-TD-DATE-MODIF">
                  <span tabindex="0" class="update-action-name"
                    >{{ "labels.subfolder.listView.updateDate" | translate }}
                  </span>
                  <br /><span tabindex="0" class="update-date"
                    >{{ "labels.subfolder.listView.at" | translate
                    }}{{ subfolder.updatedate
                    }}{{ "labels.subfolder.listView.to" | translate
                    }}{{ subfolder.updatetime }}</span
                  >
                </div>
                <div id="RECH-TD-MODIFICATEUR" style="display: contents">
                  <span tabindex="0" class="user-updater"
                    >{{ "labels.subfolder.listView.by" | translate
                    }}{{ subfolder.lastUpdateAuthor }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons-group">
          <button
            id="RECH-TD-BT-CONSULT"
            aria-label="consulter le télédossier"
            role="button"
            (click)="onConsultButtonClicked(subfolder)"
            [disabled]="subfoldersToUpdate.includes(subfolder.id)"
            class="consult-button"
            mat-flat-button
          >
            {{ "labels.subfolder.listView.consultButton" | translate }}
          </button>
          <div
            role="tooltip"
            matTooltip="Téléchargement impossible"
            [matTooltipDisabled]="subfolder.status != closedStatus"
          >
            <div *ngxPermissionsOnly="['GestionTD']">
              <button
                *ngIf="!isAdmNat()"
                id="RECH-TD-BT-TELECHARGER"
                aria-label="télécharger le télédossier"
                role="button"
                (click)="onDownloadButtonClicked(subfolder)"
                [disabled]="
                  subfolder.status == closedStatus ||
                  subfoldersToUpdate.includes(subfolder.id)
                "
                class="download-button"
                mat-flat-button
              >
                {{ "labels.subfolder.listView.downloadButton" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="buttons-group-icons">
          <button
            aria-label="consulter le télédossier"
            role="button"
            class="consult-button"
            mat-flat-button
          >
            <i class="fas fa-eye"></i>
          </button>
          <button
            aria-label="télécharger le télédossier"
            role="button"
            class="download-button"
            mat-flat-button
          >
            <i class="fas fa-file-download"></i>
          </button>
        </div>
      </section>
    </section>
  </ng-container>
  <mat-paginator
    class="test-color"
    tabindex="0"
    aria-label="pagination"
    *ngIf="dataSource.total > 9"
    id="subfolders-paginator"
    (page)="handlePageEvent($event)"
    [length]="totalSubfoldersNumber"
    [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
  >
  </mat-paginator>
  <div class="app-subfolders-action-bar">
    <app-subfolders-action-bar
      tabindex="0"
      aria-label="barre d’outils de sélection et d’action par lot. Selectionnez dans la liste les dossiers souhaitées pour traitement "
      [selectedSubfolders]="selectedSubfolders"
      [potentialStatuses]="potentialStatuses"
      [disabledStatusButtons]="disabledStatusButtons"
      [isUniqueStatusSelected]="isUniqueStatusSelected"
      (DownloadButtonClickedEvent)="downloadAllSelectedSubfolders()"
      (ModifyStatusesClickedEvent)="mofifySelectedSubfoldersStatuses($event)"
      [disableDownloadButton]="disableDownloadButton()"
      *ngIf="selectedSubfolders.length > 0 && !disabled"
    >
    </app-subfolders-action-bar>
  </div>
</div>
