<section class="section__page-header rf-container-fluid">
    <div class="error rf-grid-row">
        <div class="message rf-col-12">
          {{ "labels.subfolder.details.errors.idNotFound.label" | translate }}
        </div>
        <a
          aria-labelledby="gotolist"
          title="retour à la liste"
          class="goToList rf-col-12"
          [routerLink]="['/subfolders']"
          >{{
            "labels.subfolder.details.errors.idNotFound.linkToList" | translate
          }}</a
        >
      </div>
</section>