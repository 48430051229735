import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subfolder } from 'src/app/shared/models/subfolder';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root',
})
export class SubfoldersSharedService extends AbstractService {
  private allSubfolders: BehaviorSubject<Subfolder[]> = new BehaviorSubject([]);
  private disableSelectAllCheckBox: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public setAllSubfolders(value: Subfolder[]) {
    this.allSubfolders.next(value);
  }

  public getAllSubfolders() {
    return this.allSubfolders.asObservable();
  }

  public setDisableSelectAllCheckBox(value: boolean) {
    this.disableSelectAllCheckBox.next(value);
  }

  public getDisableSelectAllCheckBox() {
    return this.disableSelectAllCheckBox.asObservable();
  }
}
