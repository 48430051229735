import { Attachment } from './attachment';

export interface History {
  id?: number;
  subfolderId: number;
  subfolderCurrentStatus?: number;
  subfolderNewStatus?: string;
  actionType?: number;
  message?: string;
  comment?: string;
  messageTransmission?: boolean;
  attachments?: Attachment[];
  updateTimestamp: Date;
  formattedDate?: string;
  formattedTime?: string;
  author: string;
  status?: number;
  userName?: string;
  subfolderList?: number[];
}

export enum actionType {
  COMM_ADD = 0,
  SENDING_MESSAGE = 1,
  ATTACH_DL = 2,
  ATTACH_DEPOSIT = 3,
  STATUS_UPDATE = 4,
  ATTACH_DL_ALL = 5,
}

export enum historyStatus {
  SENT = 0,
  RECEIVED = 1,
}
