<div
  tabindex="0"
  role="alert"
  aria-labelledby="box-title"
  class="update-status-box-content"
>
  <div class="title-area" mat-dialog-title>
    <div class="title-right-side">
      <h5>
        <div id="CONSULT-BD-STATUT-TITRE" class="title">
          {{
            "labels.subfolder.confirmationUpdateStatusDialogBox.title"
              | translate
          }}
        </div>
      </h5>
    </div>
    <i
      tabindex="-1"
      aria-label="annuler la mise à jour"
      role="button"
      class="fas fa-times"
      mat-dialog-close="cancel"
      id="CONSULT-BD-STATUT-CLOSE"
    ></i>
  </div>
  <mat-dialog-content>
    <div
      *ngIf="!data.isMultipleSelection"
      tabindex="0"
      aria-label="résumé du mise à jour du statut"
      class="changing-status-summary"
    >
      {{ "labels.subfolder.confirmationReceptionDialogBox.from" | translate }}
      <div
        id="CONSULT-BD-STATUT-COURANT"
        tabindex="0"
        aria-label="statut actuel"
        class="old-status"
        *ngIf="data && data.subfolder && data.subfolder.status"
      >
        <i
          class="{{
            'subfolder.statusIcon.' + data.subfolder.status | translate
          }}"
        ></i>
        <div tabindex="0" class="status-name">
          {{ "subfolder.status." + data.subfolder.status | translate }}
        </div>
      </div>
      <div id="CONSULT-BD-STATUT-SENS" style="display: contents">
        {{ "labels.subfolder.confirmationReceptionDialogBox.to" | translate }}
      </div>
      <div
        id="CONSULT-BD-STATUT-SUIVANT"
        tabindex="0"
        aria-label="statut mise à jour"
        class="new-status"
      >
        <i
          class="{{ 'subfolder.statusIcon.' + data.nextStatus | translate }}"
        ></i>
        <div tabindex="0" class="status-name">
          {{ "subfolder.status." + data.nextStatus | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="!isUploading">
      <div
        *ngIf="data.subfolder.process.messageWithStatus"
        class="message-for-requester-section"
      >
        <div class="col-12 d-flex flex-column px-2 pt-3">
          <mat-form-field id="CONSULT-BD-STATUT-MESSAGE">
            <mat-label
              tabindex="0"
              aria-label="ajouter un commentaire destiné à la personne concernée"
              >{{
                "labels.subfolder.details.comments.placeholderRequesterComment"
                  | translate
              }}</mat-label
            >
            <textarea
              tabindex="0"
              matInput
              [maxlength]="500"
              name="comment"
              matInput
              [(ngModel)]="data.message"
            ></textarea>
          </mat-form-field>
          <div tabindex="0" class="character-limit text-right">
            {{
              "labels.subfolder.details.comments.charactersLimit" | translate
            }}
            {{ data.message?.length }}/500
          </div>
        </div>
      </div>
      <div id="CONSULT-BD-STATUT-MESSAGE-INFO" style="display: contents">
        <div tabindex="0" class="update-text" *ngIf="data.messageTransmission">
          {{ "labels.subfolder.updateMessage1" | translate }}
        </div>
        <div tabindex="0" class="update-text pt-3">
          {{ "labels.subfolder.updateComment" | translate }}
        </div>
      </div>
      <div id="CONSULT-BD-STATUT-COM" class="internal-comment-section">
        <div class="col-12 d-flex flex-column px-2 pt-1">
          <mat-form-field>
            <mat-label
              tabindex="0"
              aria-label="ajouter un commentaire interne"
              >{{
                "labels.subfolder.details.comments.placeholder" | translate
              }}</mat-label
            >
            <textarea
              tabindex="0"
              matInput
              [maxlength]="500"
              name="comment"
              matInput
              [(ngModel)]="data.comment"
            ></textarea>
          </mat-form-field>
          <div tabindex="0" class="character-limit text-right">
            {{
              "labels.subfolder.details.comments.charactersLimit" | translate
            }}
            {{ data.comment?.length }}/500
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isUploading">
      <div class="col-12">
        <app-upload-files-zone
          [subfolder]="data.subfolder"
          [isUploadingFromUpdatingBox]="isUploadingFromUpdatingBox"
          (CancelUploadButtonClickedEvent)="cancelUpload()"
          (ConfirmUploadButtonClickedEvent)="
            uploadFromUpdatingDialogBox($event)
          "
        >
        </app-upload-files-zone>
      </div>
    </div>
    <div id="uploaded-files-list-section">
      <div
        class="attachment"
        *ngFor="let attachment of attachments; let i = index"
      >
        <div class="link-content">
          <span id="CONSULT-BD-STATUT-PJ-FORMAT" class="icon">
            <fa-icon [icon]="attachment?.mimeType | fileIcon"></fa-icon>
          </span>
          <span id="CONSULT-BD-STATUT-PJ-NAME" class="filename">{{
            attachment.name
          }}</span>
          <span id="CONSULT-BD-STATUT-PJ-SIZE" class="size"
            >({{ attachment?.size | fileSize }})</span
          >
        </div>
        <button
          id="CONSULT-BD-STATUT-PJ-DELETE"
          aria-label="supprimer le fichier"
          role="button"
          class="delete-file"
          (click)="deleteAttachment(i); $event.stopPropagation()"
        >
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="!isUploading">
    <ng-container>
      <div id="CONSULT-BD-STATUT-BT-DEPOT-PJ" style="display: contents">
        <button
          *ngIf="isUploadAllowedByProcess()"
          aria-label="charger un fichier"
          role="button"
          type="button"
          mat-stroked-button
          id="uploadButton"
          (click)="openUploadZone()"
        >
          <i class="fa fa-file-upload fa-lg" aria-hidden="true"></i>
          <span class="button-label">
            {{ "labels.subfolder.details.attachments.upload" | translate }}
          </span>
        </button>
      </div>
    </ng-container>

    <div
      [ngClass]="{
        'right-alignment':
          (data.subfolder.process.attachmentsFormatsEnabled &&
            data.subfolder.process.attachmentsFormatsEnabled.length === 0) ||
          !data.subfolder.process.attachmentsFormatsEnabled
      }"
      class="save-cancel-actions"
    >
      <button
        id="CONSULT-BD-STATUT-BT-ANNULER"
        aria-label="annuler la mise à jour"
        role="button"
        class="cancel-button"
        mat-button
        mat-dialog-close
        mat-dialog-close="cancel"
      >
        {{
          "labels.subfolder.confirmationUpdateStatusDialogBox.cancelButton"
            | translate
        }}
      </button>
      <button
        id="CONSULT-BD-STATUT-BT-VALIDER"
        aria-label="confirmer la mise à jour"
        role="button"
        class="confirm-button"
        mat-button
        mat-dialog-close
        [mat-dialog-close]="data.isCheckboxSelected"
        mat-dialog-close="confirm"
        (click)="dialogRef.close(data)"
      >
        {{
          "labels.subfolder.confirmationUpdateStatusDialogBox.confirmButton"
            | translate
        }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
