import { NgModule } from '@angular/core';
import { SubfoldersListViewComponent } from './components/subfolders-list-view/subfolders-list-view.component';
import { SubfoldersTableViewComponent } from './components/subfolders-table-view/subfolders-table-view.component';
import { SubFoldersRoutingModule } from './subfolders.route';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { SubfoldersFiltersComponent } from './components/subfolders-filters/subfolders-filters.component';
import { SubfoldersPageComponent } from './components/subfolders-page/subfolders-page.component';
import { SubfoldersStatusTabsComponent } from './components/subfolders-status-tabs/subfolders-status-tabs.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { SubfoldersActionBarComponent } from './components/subfolders-action-bar/subfolders-action-bar.component';
import { ConfirmationReceptionDialogBoxComponent } from './modals/confirmation-dialog-box/confirmation-dialog-box.component';
import { SubfoldersDetailsUpdateStatusComponent } from './modals/subfolders-details-update-status-component/subfolders-details-update-status-component.component';
import { UpdateMultiplesSubfoldersDialogBoxComponent } from './modals/update-multiples-subfolders-dialog-box/update-multiples-subfolders-dialog-box.component';
import { SendMessageDialogBoxComponent } from './modals/send-message-dialog-box/send-message-dialog-box.component';
import { UploadFilesZoneComponent } from './components/subfolders-details/upload-files-zone/upload-files-zone.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { CancelUpdateDialogBoxComponent } from './modals/cancel-update-dialog-box/cancel-update-dialog-box.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UsersRoutingModule } from '../users/users.route';
import { SubfoldersDetailsAttachmentComponent } from './components/subfolders-details/subfolders-details-attachment/subfolders-details-attachment.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FileIconPipe } from './pipes/file-icon.pipe';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faFileImage,
  faFileWord,
  faFilePdf,
  faFileCode,
} from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDownloadDialogBoxComponent } from './modals/confirmation-download-box/confirmation-download-box.component';
import { SubfoldersDetailsPageComponent } from './components/subfolders-details/subfolders-details-page/subfolders-details-page.component';
import { SubfoldersDetailsAttachmentListComponent } from './components/subfolders-details/subfolders-details-attachment-list/subfolders-details-attachment-list.component';
import { SubfoldersDetailsBannerErrorComponent } from './components/subfolders-details/subfolders-details-banner-error/subfolders-details-banner-error.component';
import { SubfoldersDetailsBannerComponent } from './components/subfolders-details/subfolders-details-banner/subfolders-details-banner.component';
import { SubfoldersDetailsCommentComponent } from './components/subfolders-details/subfolders-details-comment/subfolders-details-comment.component';
import { SubfoldersDetailsHistoryComponent } from './components/subfolders-details/subfolders-details-history/subfolders-details-history.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TextService } from 'src/app/shared/service/text.service';

@NgModule({
  declarations: [
    SubfoldersListViewComponent,
    SubfoldersTableViewComponent,
    SubfoldersFiltersComponent,
    SubfoldersPageComponent,
    SubfoldersStatusTabsComponent,
    SubfoldersActionBarComponent,
    ConfirmationReceptionDialogBoxComponent,
    ConfirmationDownloadDialogBoxComponent,
    SubfoldersDetailsUpdateStatusComponent,
    UpdateMultiplesSubfoldersDialogBoxComponent,
    SendMessageDialogBoxComponent,
    UploadFilesZoneComponent,
    DragDropDirective,
    CancelUpdateDialogBoxComponent,
    SubfoldersDetailsPageComponent,
    SubfoldersDetailsBannerComponent,
    SubfoldersDetailsBannerErrorComponent,
    SubfoldersDetailsAttachmentComponent,
    SubfoldersDetailsAttachmentListComponent,
    SubfoldersDetailsHistoryComponent,
    SubfoldersDetailsCommentComponent,
    FileSizePipe,
    FileIconPipe,
    ConfirmationReceptionDialogBoxComponent,
    SubfoldersDetailsUpdateStatusComponent,
    CancelUpdateDialogBoxComponent,
  ],
  imports: [
    SharedModule,
    SubFoldersRoutingModule,
    UsersRoutingModule,
    CommonModule,
    MatCardModule,
    NgxPermissionsModule,
    FontAwesomeModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [TextService],
  exports: [
    TranslateModule,
    SubfoldersPageComponent,
    SubfoldersDetailsHistoryComponent,
    SubfoldersDetailsAttachmentListComponent,
    SubfoldersDetailsBannerErrorComponent,
  ],
})
export class SubfoldersModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faFileImage, faFileWord, faFilePdf, faFileCode);
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
