import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  OnChanges,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  statusesList,
  SubfolderStatus,
} from '../../../../shared/models/subfolder';
import { ApplicationException } from '../../../../shared/exceptions/application.exception';
import { SubfoldersService } from '../../services/subfolders.service';
import { SubFolderResult } from '../../models/subfolder-result';
import { SubfoldersCount } from '../../models/subfoldersCount';
@Component({
  selector: 'app-subfolders-status-tabs',
  templateUrl: './subfolders-status-tabs.component.html',
  styleUrls: ['./subfolders-status-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubfoldersStatusTabsComponent implements OnInit, OnChanges {
  @Input() dataSource: SubFolderResult;
  @Input() newStatusSubfolderCount: SubfoldersCount;
  @Input() oldStatusSubfolderCount: SubfoldersCount;
  statuses: SubfolderStatus[] = statusesList;
  currentStatus: number;
  urlStatusId: number;
  subfolderCount: SubfoldersCount[] = [];
  chosenStatus: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subfoldersService: SubfoldersService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.urlStatusId = +params.get('status');
    });
    this.getSubfoldersCount();
  }

  ngOnChanges(): void {
    if (this.newStatusSubfolderCount != null) {
      this.subfolderCount.forEach((subfolder: SubfoldersCount) => {
        if (this.newStatusSubfolderCount.status === subfolder.status) {
          subfolder.count += this.newStatusSubfolderCount.count;
        }
        if (this.oldStatusSubfolderCount.status === subfolder.status) {
          subfolder.count -= this.oldStatusSubfolderCount.count;
        }
      });
      this.newStatusSubfolderCount = null;
      this.oldStatusSubfolderCount = null;
    }
  }

  public async getSubfoldersCount(): Promise<void> {
    await this.subfoldersService
      .getSubfoldersCount()
      .then((subfolderCount: SubfoldersCount[]) => {
        this.subfolderCount = subfolderCount;
        return subfolderCount;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public getNumberOfResultsByStatus(status: number): number {
    let total = 0;
    if (this.subfolderCount) {
      this.subfolderCount.forEach((statusCount) => {
        if (statusCount.status === status) {
          total = statusCount.count;
        }
      });
    }
    if(total < 0){
      total = 0;
    } 
      return total;
    
  }

  onTabClicked(chosenStatus: number): void {
    this.chosenStatus = chosenStatus;
    this.getListByStatus(chosenStatus);
  }

  getListByStatus(chosenStatus: number): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        status: chosenStatus,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
    this.currentStatus = chosenStatus;
  }
}
