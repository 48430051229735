import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Attachment } from 'src/app/shared/models/attachment';
import { Subfolder, SubfolderIn } from 'src/app/shared/models/subfolder';
import { History } from 'src/app/shared/models/history';
import { saveAs } from 'file-saver';
import { OAuthService } from 'angular-oauth2-oidc';
import { HistoryService } from '../../../services/history.service';
import { SubfoldersHttpService } from '../../../services/subfoldersHttp.service';
import { SnackbarService } from 'src/app/shared/service/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PreferencesService } from 'src/app/shared/service/preferences.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-subfolders-details-page',
  templateUrl: './subfolders-details-page.component.html',
  styleUrls: ['./subfolders-details-page.component.scss'],
})
export class SubfoldersDetailsPageComponent implements OnInit {
  subfolder: Subfolder;
  subfolderHisotires: History[] = [];
  error = false;
  user: string;
  disableUpdateStatus: boolean;
  constructor(
    private subfoldersHttpService: SubfoldersHttpService,
    private route: ActivatedRoute,
    private historyService: HistoryService,
    private oauthService: OAuthService,
    private snackbar: SnackbarService,
    private preferencesService: PreferencesService,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.disableUpdateStatus = false;
    this.getSubfolder();
    this.getUser();
  }

  getSubfolder(): void {
    const subfolderId: number = Number.parseInt(
      this.route.snapshot.paramMap.get('id'),
      10
    );
    this.subfoldersHttpService.getSubfolderById(subfolderId).subscribe(
      (subfolder) => {
        this.subfolder = subfolder;
        this.subfolderHisotires = this.getSubfolderHistory();
      },
      () => (this.error = true)
    );
  }

  getSubfolderHistory() {
    return this.subfolder.history.filter(
      (history) => ![undefined, null, ''].includes(history.author)
    );
  }

  getUser(): void {
    const claims: any = this.oauthService.getIdentityClaims();
    if (claims) {
      this.user = `${
        claims.given_name.charAt(0).toUpperCase() + claims.given_name.slice(1)
      } ${claims.family_name.toUpperCase()}`;
    }
  }

  updateStatus(event): void {
    this.disableUpdateStatus = true;
    this.subfoldersHttpService
      .updateStatus(this.subfolder.id, event.subfolderIn, event.files)
      .subscribe({
        next: (subfolder: Subfolder) => {
          this.disableUpdateStatus = false;
          this.subfolder = subfolder;
          this.snackbar.updateStatusSuccess();
        },
        error: (error: HttpErrorResponse) => {
          this.disableUpdateStatus = false;
          if (error.status === 409) {
            this.snackbar.dataHasChanged();
          } else {
            this.snackbar.unknownError();
          }
        },
      });
  }

  sendMessage(message: string): void {
    this.historyService.sendMessage(this.subfolder, message).subscribe({
      next: (history: History) => {
        this.subfolder.history.unshift(history);
        this.snackbar.openSnackbar(
          'labels.subfolder.details.comments.snackbar.addMessSuccess'
        );
      },
      error: () =>
        this.snackbar.openSnackbar(
          'labels.subfolder.details.comments.snackbar.addMessError'
        ),
    });
  }

  downloadFile(attachment: Attachment): void {
    const history: History = this.historyService.createDownloadHistory(
      this.subfolder,
      attachment.fileName
    );
    this.subfoldersHttpService
      .downloadAttachment(this.subfolder.id, history, attachment.middlewareID)
      .subscribe({
        next: (file: Blob) => {
          saveAs(file, attachment.fileName);
          this.subfolder.history.unshift(history);
          this.snackbar.downloadFileSucceeded();
        },
        error: () => this.snackbar.downloadFailedDetailsPage(),
      });
  }

  downloadAllFiles(): void {
    const history: History = this.historyService.createDownloadAllHistory(
      this.subfolder
    );
    this.subfoldersHttpService
      .downloadAllAttachments(
        this.subfolder.id,
        history,
        this.preferencesService.getUserIncludeXML()
      )
      .subscribe({
        next: (file: Blob) => {
          const latest_date = this.datepipe.transform(
            new Date(),
            'yyyyMMdd-HH.mm'
          );
          saveAs(
            file,
            latest_date + '_' + this.subfolder.subfolderNumber + '.zip'
          );
          this.subfolder.history.unshift(history);
          this.snackbar.openSnackbar(
            'labels.subfolder.details.attachments.snackbar.downloadAllSuccess'
          );
          if (+this.subfolder.status < 4) {
            const subfolderIn: SubfolderIn = {
              status: 4,
              comment: '',
              subfoldersIds: null,
              author: this.user,
              messageTransmission: true,
              currentStatus: +this.subfolder.status,
              message: '',
            };
            const event = {
              subfolderIn,
              files: null,
            };
            this.updateStatus(event);
          }
        },
        error: () => this.snackbar.downloadFailedDetailsPage(),
      });
  }

  uploadFiles(files: File[]): void {
    const history: History = this.historyService.createUploadHistory(
      this.subfolder,
      files
    );
    this.subfoldersHttpService
      .uploadAttachments(this.subfolder.id, history, files)
      .subscribe({
        next: (retrievedHistory: History) => {
          this.snackbar.openSnackbar(
            'labels.subfolder.details.attachments.snackbar.uploadSuccess'
          );
          this.getSubfolder();
        },
        error: () => {
          this.snackbar.openSnackbar(
            'labels.subfolder.details.attachments.snackbar.uploadFail'
          );
        },
      });
  }

  addComment(comment: string): void {
    this.historyService.addComment(this.subfolder, comment).subscribe({
      next: (history) => {
        this.subfolder.history.unshift(history);
        this.snackbar.openSnackbar(
          'labels.subfolder.details.comments.snackbar.addCommSuccess'
        );
      },
      error: () =>
        this.snackbar.openSnackbar(
          'labels.subfolder.details.comments.snackbar.addCommError'
        ),
    });
  }
}
