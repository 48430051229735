import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  statusesList,
  Subfolder,
  SubfolderIn,
  SubfolderStatus,
} from 'src/app/shared/models/subfolder';
import { SendMessageDialogBoxComponent } from '../../../modals/send-message-dialog-box/send-message-dialog-box.component';
import { SubfoldersDetailsUpdateStatusComponent } from '../../../modals/subfolders-details-update-status-component/subfolders-details-update-status-component.component';
import { SubfoldersService } from '../../../services/subfolders.service';
import { Location } from '@angular/common';
import { History, actionType } from 'src/app/shared/models/history';
import { SnackbarService } from 'src/app/shared/service/snackbar.service';

@Component({
  selector: 'app-subfolders-details-banner',
  templateUrl: './subfolders-details-banner.component.html',
  styleUrls: ['./subfolders-details-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubfoldersDetailsBannerComponent implements OnInit, DoCheck {
  @Input()
  subfolder: Subfolder;

  @Input()
  disableUpdateStatus: boolean;

  @Output()
  sendMessage: EventEmitter<void> = new EventEmitter();

  @Output()
  updateStatus: EventEmitter<{ subfolderIn: SubfolderIn; files: File[] }> =
    new EventEmitter();

  @Output()
  uploadFilesEventFromUpdatingDialogBox: EventEmitter<File[]> =
    new EventEmitter();

  statuses: Array<SubfolderStatus>;
  user: string;
  historyInitialLength = 0;

  constructor(
    private oauthService: OAuthService,
    private dialog: MatDialog,
    public subfoldersService: SubfoldersService,
    private location: Location,
    private snackbar: SnackbarService
  ) {}

  ngOnInit(): void {
    const claims: any = this.oauthService.getIdentityClaims();
    if (claims) {
      this.user = `${
        claims.given_name.charAt(0).toUpperCase() + claims.given_name.slice(1)
      } ${claims.family_name.toUpperCase()}`;
    }
  }

  ngDoCheck(): void {
    if (this.subfolder) {
      this.statuses = this.getAvailableStatuses(
        Number.parseInt(this.subfolder.status, 10)
      );

      if (
        this.subfolder.history &&
        this.historyInitialLength !== this.subfolder.history.length
      ) {
        if (this.historyInitialLength !== 0) {
          let shallowHistory: History[] = Object.assign(
            [],
            this.subfolder.history
          );
          shallowHistory = shallowHistory.sort(
            (h1: History, h2: History) =>
              new Date(h2.updateTimestamp.toString()).getTime() -
              new Date(h1.updateTimestamp.toString()).getTime()
          );
          this.subfolder.updateTimestamp = shallowHistory[0].updateTimestamp;
        }
        this.historyInitialLength = this.subfolder.history.length;
      }
    }
  }

  getAvailableStatuses(statusId: number): Array<SubfolderStatus> {
    let availableStatuses: Array<SubfolderStatus> =
      this.getNextAndNotClosesStatuses(statusId);
    availableStatuses =
      this.removeAwaitingStatusIfAuthorizedAwaitingIsFalse(availableStatuses);
    return availableStatuses;
  }

  removeAwaitingStatusIfAuthorizedAwaitingIsFalse(
    statuses: Array<SubfolderStatus>
  ): Array<SubfolderStatus> {
    return statuses.filter(
      (status) => status.id !== 5 || this.subfolder?.process.authorizedAwaiting
    );
  }

  getNextAndNotClosesStatuses(statusId: number): Array<SubfolderStatus> {
    return statusesList.filter(
      (status) => status.id > statusId && status.id !== 8
    );
  }

  userHasRoleSubfolderManagement(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();

    if (!claims) {
      return false;
    }

    return claims.groups.indexOf('GestionTD') !== -1;
  }

  openSendMessageDialog(): void {
    const dialogRef = this.dialog.open(SendMessageDialogBoxComponent, {
      width: '50%',
      autoFocus: false,
      data: { subfolder: this.subfolder },
    });

    dialogRef.afterClosed().subscribe((message) => {
      if (message && message !== 'cancel') {
        this.sendMessage.emit(message);
      }
    });
  }

  openStatusMessageDialog(status: string): void {
    /**
     * Si le statut est 'En cours', 'Refusé' ou 'Traité' alors il faut
     * vérifier que les PJs du TD ont été téléchargées
     */
    if ([4, 6, 7].includes(parseInt(status)) && !this.canChangeStatus()) {
      this.snackbar.errorOnStatusUpdate(parseInt(status));
    } else {
      const dialogRef = this.dialog.open(
        SubfoldersDetailsUpdateStatusComponent,
        {
          autoFocus: false,
          data: {
            subfolder: this.subfolder,
            nextStatus: status,
            messageTransmission: true,
            isUploadingFromUpdatingBox: true,
          },
        }
      );

      dialogRef.afterClosed().subscribe((data) => {
        if (data && data !== 'cancel') {
          const updateSubfolder: SubfolderIn = {
            status: +status,
            comment: data.comment,
            message: data.message,
            subfoldersIds: null,
            author: this.user,
            messageTransmission: true,
            currentStatus: +this.subfolder.status,
            attachements: data.attachements,
          };
          const uploadedFiles = data.attachements;
          this.updateStatus.emit({
            subfolderIn: updateSubfolder,
            files: uploadedFiles,
          });
        }
      });
    }
  }

  canChangeStatus(): boolean {
    return (
      (this.subfolder.attachments && this.subfolder.attachments.length === 0) ||
      (this.subfolder.history &&
        this.subfolder.history.some(
          (history) =>
            history.actionType === actionType.ATTACH_DL ||
            history.actionType === actionType.ATTACH_DL_ALL
        )) ||
      this.subfolder.purgedBinary
    );
  }

  goBack(): void {
    this.location.back();
  }
}
