import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-subfolders-details-comment',
  templateUrl: './subfolders-details-comment.component.html',
  styleUrls: ['./subfolders-details-comment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubfoldersDetailsCommentComponent {
  @Output()
  addComment: EventEmitter<string> = new EventEmitter();

  comment = '';

  public sendComment(event: string): void {
    if (event !== '') {
      this.addComment.emit(event);
      this.comment = '';
    }
  }
}
