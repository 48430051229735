import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubfoldersDetailsPageComponent } from './components/subfolders-details/subfolders-details-page/subfolders-details-page.component';
import { SubfoldersPageComponent } from './components/subfolders-page/subfolders-page.component';

const routes: Routes = [
  { path: '', component: SubfoldersPageComponent },
  { path: ':id', component: SubfoldersDetailsPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubFoldersRoutingModule {}
