<main
  tabindex="0"
  aria-label="liste et filtres pour les télédossiers"
  class="subfolders-page-container"
>
  <div class="app-subfolders-status-tabs">
    <app-subfolders-status-tabs
      [dataSource]="subsResult"
      [newStatusSubfolderCount]="newStatusSubfolderCount"
      [oldStatusSubfolderCount]="oldStatusSubfolderCount"
    ></app-subfolders-status-tabs>
  </div>
  <div class="bottom-content" *ngIf="subsResult">
    <div class="app-subfolders-filters">
      <app-subfolders-filters
        *ngIf="subsResult.subfolders"
        [dataSource]="subsResult"
        (selectedOrUnselectedAllSubfolders)="selectAllSubfolders($event)"
        [isSelectionCleaned]="isSelectionCleaned"
        [isAllSelected]="isAllSelected"
      ></app-subfolders-filters>
    </div>
    <ng-template ng-template [ngIf]="view === 'table'" [ngIfElse]="list">
      <app-subfolders-table-view
        [dataSource]="subsResult"
        [hasResults]="hasResults"
      >
      </app-subfolders-table-view>
    </ng-template>
    <ng-template #list>
      <app-subfolders-list-view
        [dataSource]="subsResult"
        [hasResults]="hasResults"
        [filters]="filters"
        [isAllSelected]="isAllSelected"
        (refreshSubfoldersListEvent)="getResultsByQueryParams()"
        (cleanSelectionEvent)="cleanSelection()"
        (isSelectionModifiedEvent)="uncheckSelectAllCheckBox()"
        (updateOnSubfolderCountNewStatus)="getNewSubfolderCount($event, true)"
        (updateOnSubfolderCountOldStatus)="getNewSubfolderCount($event, false)"
      ></app-subfolders-list-view>
    </ng-template>
  </div>
</main>
