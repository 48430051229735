import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { environment_endpoints } from 'src/environments/environment';
import { History } from 'src/app/shared/models/history';

@Injectable({
  providedIn: 'root',
})
export class HistoryHttpService {
  private token = '';
  private headers: HttpHeaders;
  private urlHistory = environment_endpoints.api.history;

  constructor(
    private httpClient: HttpClient,
    private oauthService: OAuthService
  ) {
    this.token = this.oauthService.getIdToken();
    this.headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + this.token)
      .append('AccessToken', this.oauthService.getAccessToken());
  }

  postHistory(history: History): Observable<History> {
    return this.httpClient.post<History>(
      `${this.urlHistory}/${history.subfolderId}`,
      history,
      { headers: this.headers }
    );
  }
}
