<div class="action-bar-content">
  <div class="left-side">
    <div class="selected-number-text">
      {{ selectedSubfolders.length
      }}{{ "labels.subfolder.actionBar.selectedNumber" | translate }}
    </div>
    <div class="buttons-group">
      <div
        class="tooltip-info"
        matTooltip=" {{
          'labels.subfolder.actionBar.toolTip.disableDownloadButton' | translate
        }}"
        matTooltipPosition="above"
        [matTooltipDisabled]="!disableDownloadButton"
      >
        <button
          aria-label="télécharger les télédossiers"
          role="button"
          (click)="onDownloadButtonClicked()"
          class="download-button"
          mat-flat-button
          [disabled]="disableDownloadButton || disabledStatusButtons"
          id="RECH-TD-BT-SELECT-TELECHARGE"
          *ngIf="!isAdmNat()"
        >
          {{ "labels.subfolder.actionBar.downloadButton" | translate }}
        </button>
      </div>

      <ng-container *ngIf="isUniqueStatusSelected">
        <ng-container *ngFor="let status of potentialStatuses">
          <button
            id="RECH-TD-BT-SELECT-ACTIONS"
            aria-label="modifier le statuts des télédossiers"
            role="button"
            [disabled]="disabledStatusButtons"
            (click)="onModifyStatusesButtonClicked(status)"
            class="action-button"
            mat-flat-button
          >
            {{ status.actionName }}
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
