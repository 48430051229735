<div
  tabindex="0"
  role="alert"
  aria-labelledby="box-title"
  class="confirmation-dialog-box-content"
>
  <div class="title-area" mat-dialog-title>
    <div class="title-right-side">
      <div>
        <i id="BD-RECEPTION-ALERT-ICO" class="fas fa-exclamation-triangle"></i>
      </div>
      <h5>
        <div id="BD-RECEPTION-TITRE" class="title">{{ data.title }}</div>
      </h5>
    </div>
    <i
      id="BD-RECEPTION-CLOSE"
      aria-label="annuler la mise à jour"
      class="fas fa-times"
      mat-dialog-close="cancel"
    ></i>
  </div>
  <mat-dialog-content>
    <div
      *ngIf="!data.isMultipleSelection"
      tabindex="0"
      aria-label="résumé du mise à jour du statut"
      class="changing-status-summary"
    >
      {{ "labels.subfolder.confirmationReceptionDialogBox.from" | translate }}
      <div
        id="BD-RECEPTION-STATUT-COURANT"
        tabindex="0"
        aria-label="statut actuel"
        class="old-status"
        *ngIf="data && data.subfolder && data.subfolder.status"
      >
        <i
          class="{{
            'subfolder.statusIcon.' + data.subfolder.status | translate
          }}"
        ></i>
        <div tabindex="0" class="status-name">
          {{ "subfolder.status." + data.subfolder.status | translate }}
        </div>
      </div>
      <span id="BD-RECEPTION-STATUT-SENS">
        {{
          "labels.subfolder.confirmationReceptionDialogBox.to" | translate
        }}</span
      >
      <div
        id="BD-RECEPTION-STATUT-SUIVANT"
        tabindex="0"
        aria-label="statut mise à jour"
        class="new-status"
      >
        <i
          class="{{ 'subfolder.statusIcon.' + data.nextStatus | translate }}"
        ></i>
        <div tabindex="0" class="status-name">
          {{ "subfolder.status." + data.nextStatus | translate }}
        </div>
      </div>
    </div>
    <div id="BD-RECEPTION-TEXTE" tabindex="0" class="message">
      {{ data.messageOfReception }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      id="BD-RECEPTION-ANNULER"
      aria-label="annuler la mise à jour"
      role="button"
      class="cancel-button"
      mat-button
      mat-dialog-close
      mat-dialog-close="cancel"
    >
      {{ data.cancelButton }}
    </button>
    <button
      id="BD-RECEPTION-BT-VALIDER"
      aria-label="confirmer la mise à jour"
      role="button"
      class="confirm-button"
      mat-button
      mat-dialog-close
      mat-dialog-close="{{ data.isAdmNat ? 'confirm-no-update' : 'confirm' }}"
    >
      {{ data.confirmButtonOfReception }}
    </button>
  </mat-dialog-actions>
</div>
