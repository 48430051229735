import { KeyValue } from '@angular/common';
import { Component, DoCheck, Input, ViewEncapsulation } from '@angular/core';
import { actionType, History } from 'src/app/shared/models/history';

@Component({
  selector: 'app-subfolders-details-history',
  templateUrl: './subfolders-details-history.component.html',
  styleUrls: ['./subfolders-details-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubfoldersDetailsHistoryComponent implements DoCheck {
  @Input()
  history: Array<History> = [];

  historyDate: Map<Date, Array<History>>;
  actionTypeHtml: typeof actionType = actionType;
  historyInitialLength = 0;

  ngDoCheck(): void {
    if (this.history && this.history.length !== this.historyInitialLength) {
      this.history.sort((a, b) => this.sortHistoryByDateDesc(a, b));
      this.historyDate = new Map<Date, Array<History>>();
      this.historyInitialLength = this.history.length;
      this.mapHistoryAccordingToDate();
    }
  }

  /**
   * Maps each history event to the corresponding month, year date.
   */
  private mapHistoryAccordingToDate(): void {
    let initialDate = this.createDateFromHistory(0);
    let currentDateHistory = new Array<History>(this.history[0]);
    for (let i = 1; i < this.history.length; i++) {
      const retrievedDate = this.createDateFromHistory(i);
      if (initialDate.valueOf() === retrievedDate.valueOf()) {
        currentDateHistory.push(this.history[i]);
      } else {
        this.historyDate.set(initialDate, currentDateHistory);
        initialDate = this.createDateFromHistory(i);
        currentDateHistory = new Array<History>(this.history[i]);
      }
    }
    if (!this.historyDate.has(initialDate)) {
      this.historyDate.set(initialDate, currentDateHistory);
    }
  }

  /**
   * Sorts history by descending order date.
   *
   * @param history1 - first date to sort
   * @param history2 - second date to sort
   * @returns a value that is either negative, positive, or zero
   */
  private sortHistoryByDateDesc(history1: History, history2: History): number {
    return (
      (new Date(history2.updateTimestamp) as any) -
      (new Date(history1.updateTimestamp) as any)
    );
  }

  /**
   * Creates a year, month date from the specified index in the history array.
   *
   * @param index - history array index to extract date from
   * @returns a new year, month date
   */
  private createDateFromHistory(index: number): Date {
    const originalDate = new Date(this.history[index].updateTimestamp);
    return new Date(originalDate.getUTCFullYear(), originalDate.getUTCMonth());
  }

  /**
   * Checks if current history is a multiple actions.
   * Used with an attachment upload or a status update.
   *
   * @param history - History to perform the check on
   * @returns a boolean on whether or not the history is composed of multiple actions
   */
  public checkMultipleActions(history: History): boolean {
    if (
      (history.actionType === actionType.ATTACH_DEPOSIT &&
        history.subfolderNewStatus != null) ||
      history.message ||
      history.comment
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if author action should be displayed.
   * @param h - Specific history data to perform check on
   * @returns a boolean to display or not the author of the action
   */
  public checkAuthorDisplay(h: History): boolean {
    return (
      h.subfolderCurrentStatus > 1 &&
      (+h.subfolderNewStatus <= 8 ||
        typeof h.subfolderNewStatus === 'undefined' ||
        h.subfolderNewStatus === null)
    );
  }

  /**
   * Specific function to cancel Angular default alphabetic ordering.
   * @param a - First value to compare
   * @param b - Second value to compare
   * @returns 0
   */
  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };
}
