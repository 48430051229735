import { Subfolder } from './../../../../shared/models/subfolder';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TextService } from 'src/app/shared/service/text.service';
import { Process } from 'src/app/shared/models/process';

@Component({
  selector: 'app-subfolders-details-update-status-component',
  templateUrl: './subfolders-details-update-status-component.component.html',
  styleUrls: [
    './subfolders-details-update-status-component.component.scss',
    '../confirmation-dialog-box/confirmation-dialog-box.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [TextService],
})
export class SubfoldersDetailsUpdateStatusComponent implements OnInit {
  public isSelected: boolean;
  labelPosition: 'before' | 'after' = 'after';
  disabled: boolean;
  public commentT: string;
  isUploading = false;
  isUploadedFilesListAvailable = false;
  attachments: File[] = [];
  isUploadingFromUpdatingBox = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<SubfoldersDetailsUpdateStatusComponent>
  ) {
    this.isSelected = null;
    this.data.comment = '';
    this.data.message = '';
  }

  ngOnInit(): void {
    this.isUploadingFromUpdatingBox = this.data.isUploadingFromUpdatingBox;
  }

  openUploadZone(): void {
    this.isUploading = true;
  }

  cancelUpload(): void {
    this.isUploading = false;
  }

  uploadFromUpdatingDialogBox(files: File[]): void {
    this.isUploading = false;
    this.isUploadedFilesListAvailable = files.length > 0;
    this.attachments = this.attachments.concat(files);
    this.data.attachements = this.attachments;
  }

  deleteAttachment(index): void {
    this.attachments.splice(index, 1);
  }

  isUploadAllowedByProcess(): boolean {
    const siAttachmentStatus = this.data.subfolder.process.siAttachmentStatus;
    const subfolderStatus = this.data.subfolder.status;

    return (
      this.data.subfolder.process.attachmentsFormatsEnabled &&
      this.data.subfolder.process.attachmentsFormatsEnabled.length > 0 &&
      siAttachmentStatus &&
      siAttachmentStatus.includes(subfolderStatus)
    );
  }
}
