<div *ngIf="!hasResults" class="no-results">
  {{ "labels.subfolder.listView.noResults" | translate }}
</div>
<ng-container *ngIf="hasResults">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="data">
      <!-- STATUT Column -->
      <ng-container matColumnDef="Statut">
        <th class="text" mat-header-cell *matHeaderCellDef>
          {{ "labels.subfolder.tableView.status" | translate }}
        </th>
        <td mat-cell *matCellDef="let f" class="greycolor">
          {{ "subfolder.status." + f.status | translate }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="Nom de la démarche">
        <th class="text" mat-header-cell *matHeaderCellDef>
          {{ "labels.subfolder.tableView.process" | translate }}
        </th>
        <td style="font-weight: bold" mat-cell *matCellDef="let f">
          {{ f.process.name }}
        </td>
      </ng-container>

      <!-- REQUESTER Column -->
      <ng-container matColumnDef="Concerné">
        <th class="text" mat-header-cell *matHeaderCellDef>
          {{ "labels.subfolder.tableView.requester" | translate }}
        </th>
        <td mat-cell *matCellDef="let f">
          <span class="requester-last-name"> {{ f.applicant.lastName }}</span>
          <span class="requester-first-name">
            {{ f.applicant.firstName }}
          </span>
        </td>
      </ng-container>

      <!-- NUMBER Column -->
      <ng-container matColumnDef="Numéro">
        <th class="text" mat-header-cell *matHeaderCellDef>
          {{ "labels.subfolder.tableView.number" | translate }}
        </th>
        <td class="subfolder-number" mat-cell *matCellDef="let f">
          {{ f.subfolderNumber }}
        </td>
      </ng-container>

      <!-- SUBMITTED DATE Column -->
      <ng-container matColumnDef="date de transmission">
        <th class="text" mat-header-cell *matHeaderCellDef>
          {{ "labels.subfolder.tableView.updatedate" | translate }}
        </th>
        <td style="font-weight: bold" mat-cell *matCellDef="let f">
          {{ f.submitteddate }} à {{ f.submittedtime }}
        </td>
      </ng-container>

      <!-- UPDATE DATE Column -->
      <ng-container matColumnDef="dernière mise à jour">
        <th class="text" mat-header-cell *matHeaderCellDef>
          {{ "labels.subfolder.tableView.submitteddate" | translate }}
        </th>
        <td style="font-weight: bold" mat-cell *matCellDef="let f">
          <div *ngIf="f.lastUpdateAuthor">
            {{ f.updatedate }} à {{ f.updatetime }} <br />
            <span class="greycolor">par</span> {{ f.lastUpdateAuthor }}
          </div>
        </td>
      </ng-container>

      <!-- POINTS -->
      <ng-container matColumnDef="hi">
        <th class="text" mat-header-cell *matHeaderCellDef></th>
        <td style="font-weight: bold" mat-cell *matCellDef="let f">
          <i class="fa fa-ellipsis-h point"></i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    (page)="handlePageEvent($event)"
    [length]="totalSubfoldersNumber"
    [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
    >></mat-paginator
  >
</ng-container>
