import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { SubfolderStatus } from 'src/app/shared/models/subfolder';

@Component({
  selector: 'app-subfolders-action-bar',
  templateUrl: './subfolders-action-bar.component.html',
  styleUrls: ['./subfolders-action-bar.component.scss'],
})
export class SubfoldersActionBarComponent {
  @Input() selectedSubfolders;
  @Input() isUniqueStatusSelected;
  @Input() potentialStatuses: SubfolderStatus[];
  @Output() DownloadButtonClickedEvent: EventEmitter<boolean> =
    new EventEmitter();
  @Output() ModifyStatusesClickedEvent: EventEmitter<SubfolderStatus> =
    new EventEmitter();
  @Input() disableDownloadButton: boolean;

  @Input() disabledStatusButtons: boolean;

  constructor(
    private translate: TranslateService,
    private oauthService: OAuthService
  ) {}

  public onDownloadButtonClicked(): void {
    this.DownloadButtonClickedEvent.emit();
  }

  public onModifyStatusesButtonClicked(status: SubfolderStatus): void {
    this.ModifyStatusesClickedEvent.emit(status);
  }

  isAdmNat(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims && claims.user_type === 'ADM_NAT';
  }
}
