import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-multiples-subfolders-dialog-box',
  templateUrl: './update-multiples-subfolders-dialog-box.component.html',
  styleUrls: [
    './update-multiples-subfolders-dialog-box.component.scss',
    '../confirmation-dialog-box/confirmation-dialog-box.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class UpdateMultiplesSubfoldersDialogBoxComponent {
  public isSelected = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<UpdateMultiplesSubfoldersDialogBoxComponent>
  ) {
    this.data.comment = '';
  }
}
