import { Pipe, PipeTransform } from '@angular/core';
import { attachmentFormats } from 'src/app/shared/models/attachmentFormat';

@Pipe({
  name: 'fileIcon',
})
export class FileIconPipe implements PipeTransform {
  transform(mimeType: string): string[] {
    let icon: string[] = ['far', 'file-code'];
    attachmentFormats.forEach((format) => {
      if (mimeType === format.mimeType) {
        icon = format.icon;
      }
    });
    return icon;
  }
}
