import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { AbstractService } from 'src/app/features/subfolders/services/abstract.service';
import { environment_endpoints } from 'src/environments/environment';
import { Process } from '../models/process';

//TODO Migrate every processes endpoint usage to this service
@Injectable({
  providedIn: 'root',
})
export class ProcessService extends AbstractService {
  private headers: HttpHeaders;
  private token = '';
  private urlProcess = environment_endpoints.api.processes;

  constructor(
    private httpClient: HttpClient,
    private oauthService: OAuthService
  ) {
    super();
    this.token = this.oauthService.getIdToken();
    this.headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + this.token)
      .append('AccessToken', this.oauthService.getAccessToken());
  }

  /**
   * Retrieves all details from processes sent.
   *
   * @param listProcessPermission - list of processes from which details are wanted
   * @returns the previous list with all the details
   */
  public getProcessPermissionDetails(
    listProcessPermission: string[]
  ): Observable<Process[]> {
    let url: string;
    if (listProcessPermission !== (undefined || null)) {
      url = this.createUrlProcessPermission(listProcessPermission);
    } else {
      url = '';
    }
    return this.httpClient.get<Process[]>(
      `${this.urlProcess}/permissions?${url}`,
      { headers: this.headers }
    );
  }

  /**
   * Creates custom URL to send data to back-end about process permission code.
   *
   * @param listProcessPermission - a list of process permission code to convert
   * @returns a string with all the process code formatted
   */
  private createUrlProcessPermission(listProcessPermission: string[]): string {
    let processesUrl = 'listProcessPermission=';
    if (typeof listProcessPermission === 'string') {
      processesUrl += listProcessPermission;
      return processesUrl;
    } else {
      listProcessPermission.forEach((process: string) => {
        processesUrl += process + ',';
      });
      return processesUrl.slice(0, -1);
    }
  }
}
