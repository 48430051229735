import { faCoffee } from '@fortawesome/free-solid-svg-icons';


export interface AttachmentFormat {
  extension: string[];
  mimeType: string;
  icon: string[];
}

export const attachmentFormats: AttachmentFormat[] = [
  {
    extension: ['.jpg', '.jpeg'],
    mimeType: 'image/jpeg',
    icon: ['far', 'file-image'],
  },
  {
    extension: ['.doc'],
    mimeType: 'application/msword',
    icon: ['fas', 'file-word'],
  },
  {
    extension: ['.docx'],
    mimeType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    icon: ['far', 'file-word']
  },
  {
    extension: ['.pdf'],
    mimeType: 'application/pdf',
    icon: ['far', 'file-pdf']
  },
  {
    extension: ['.xml'],
    mimeType: 'application/xml',
    icon: ['far', 'file-code']
  },
  {
    extension: ['.xls'],
    mimeType: 'application/vnd.ms-excel',
    icon: ['far','file-excel']
  },
  {
    extension: ['.xlsx'],
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    icon: ['far','file-excel']
  }

];
