import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import {
  History,
  actionType,
  historyStatus,
} from 'src/app/shared/models/history';
import { Subfolder } from 'src/app/shared/models/subfolder';
import { HistoryHttpService } from './historyHttp.service';
import { attStatus } from 'src/app/shared/models/attachment';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(
    private oauthService: OAuthService,
    private historyHttpService: HistoryHttpService
  ) {}

  sendMessage(subfolder: Subfolder, message: string): Observable<History> {
    const history = this.initHistoryItem(
      subfolder,
      actionType.SENDING_MESSAGE,
      true,
      message,
      ''
    );
    return this.historyHttpService.postHistory(history);
  }

  addComment(subfolder: Subfolder, comment: string): Observable<History> {
    const history = this.initHistoryItem(
      subfolder,
      actionType.COMM_ADD,
      false,
      '',
      comment
    );
    return this.historyHttpService.postHistory(history);
  }

  createUploadHistory(subfolder: Subfolder, files: File[]): History {
    const history = this.initHistoryItem(
      subfolder,
      actionType.ATTACH_DEPOSIT,
      true,
      '',
      ''
    );
    history.attachments = files.map((file) => {
      return {
        id: undefined,
        type: 'DEPOT_PJ',
        fileName: file.name,
        mimeType: file.type,
        size: file.size,
        status: attStatus.PENDING,
        creationTimestamp: new Date(),
      };
    });
    return history;
  }

  createDownloadHistory(subfolder: Subfolder, fileName: string): History {
    return this.initHistoryItem(
      subfolder,
      actionType.ATTACH_DL,
      false,
      fileName,
      ''
    );
  }

  createDownloadAllHistory(subfolder: Subfolder): History {
    return this.initHistoryItem(
      subfolder,
      actionType.ATTACH_DL_ALL,
      false,
      '',
      ''
    );
  }

  initHistoryItem(
    subfolder: Subfolder,
    actionType: actionType,
    messageTransmission: boolean,
    message: string,
    comment: string
  ): History {
    return {
      author: this.getUserName(),
      subfolderId: subfolder.id,
      updateTimestamp: new Date(),
      subfolderCurrentStatus: Number.parseInt(subfolder.status),
      actionType: actionType,
      messageTransmission: messageTransmission,
      status: historyStatus.SENT,
      message: message,
      comment: comment,
    };
  }

  getUserName(): string {
    const claims: any = this.oauthService.getIdentityClaims();
    if (claims) {
      return `${
        claims.given_name.charAt(0).toUpperCase() + claims.given_name.slice(1)
      } ${claims.family_name.toUpperCase()}`;
    } else {
      return '';
    }
  }
}
