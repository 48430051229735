<div
  tabindex="0"
  aria-label="section des filtres des télédossiers"
  class="filters-container"
>
  <div class="filters">
    <div class="basic-filters-area">
      <div class="left-side">
        <mat-form-field
          appearance="fill"
          class="status-filter medium-screen small-screen"
          id="RECH-FILTRE-STATUT"
        >
          <mat-label tabindex="0">Filtrer par statut</mat-label>
          <mat-select
            [formControl]="statusesFormControl"
            (selectionChange)="onStatusSelected($event)"
            role="combobox"
          >
            <mat-option
              role="option"
              aria-checked="false"
              *ngFor="let status of statuses"
              [value]="status"
              >{{ status.statusName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field
          id="RECH-FILTRE-DEMARCHE"
          [floatLabel]="
            isLoading || (!isOpen && processes.value === null)
              ? 'never'
              : 'auto'
          "
          class="medium-large-screen"
          appearance="fill"
        >
          <mat-label tabindex="0"
            ><div
              class="deferred-select-placeholder-container"
              *ngIf="isLoading; else standardPlaceholder"
            >
              <span>{{
                "labels.subfolder.filters.filterByProcess" | translate
              }}</span>
              <mat-spinner class="spinner" diameter="20"></mat-spinner>
            </div>
            <ng-template #standardPlaceholder>{{
              "labels.subfolder.filters.filterByProcess" | translate
            }}</ng-template></mat-label
          >
          <mat-select
            [formControl]="processes"
            multiple
            (openedChange)="openChanged($event)"
            [panelClass]="
              isLoading || !isOpen ? 'hide-deferred-select-dummy-option' : ''
            "
            #matRef
            (selectionChange)="onProcessSelected($event)"
            role="combobox"
            ><mat-option
              *ngIf="processList === null || !processList.length"
              disabled
            ></mat-option>
            <mat-option
              role="option"
              aria-checked="false"
              *ngFor="let process of processList"
              [value]="process.name"
              >{{ process.code }}</mat-option
            >
          </mat-select>
          <button
            class="clean-input"
            *ngIf="filters.processes != null && filters.processes != ''"
            matSuffix
            mat-icon-button
            aria-label="Supprimer la recherche"
            (click)="clearProcessFied($event)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field
          tabindex="0"
          appearance="fill"
          class="date-filter big-screen"
          id="RECH-FILTRES-DATE-DEBUT-PERIODE"
        >
          <mat-label>{{
            "labels.subfolder.filters.startTransmitionDate" | translate
          }}</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="picker1"
            [value]="formStartDate.value"
            (keydown.enter)="getStartDate('input', $event)"
            (dateChange)="getStartDate('change', $event)"
          />
          <button
            class="clean-input"
            *ngIf="
              filters.startSubmittedDate != null &&
              filters.startSubmittedDate != ''
            "
            matSuffix
            mat-icon-button
            aria-label="Supprimer la recherche"
            (click)="clearStartSubmittedDateFied($event)"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker1>
            <ngx-mat-datepicker-actions>
              <button mat-icon-button ngxMatDatepickerCancel>
                <mat-icon>close</mat-icon>
              </button>
              <button mat-icon-button color="primary" ngxMatDatepickerApply>
                <mat-icon>check</mat-icon>
              </button>
            </ngx-mat-datepicker-actions>
          </ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field
          tabindex="0"
          appearance="fill"
          class="date-filter big-screen"
          id="RECH-FILTRES-DATE-FIN-PERIODE"
        >
          <mat-label>{{
            "labels.subfolder.filters.endTransmitionDate" | translate
          }}</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="picker2"
            [value]="formEndDate.value"
            (keydown.enter)="getEndDate('input', $event)"
            (dateChange)="getEndDate('change', $event)"
          />
          <button
            class="clean-input"
            *ngIf="
              filters.endSubmittedDate != null && filters.endSubmittedDate != ''
            "
            matSuffix
            mat-icon-button
            aria-label="Supprimer la recherche"
            (click)="clearEndSubmittedDateFied($event)"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker2>
            <ngx-mat-datepicker-actions>
              <button mat-icon-button ngxMatDatepickerCancel>
                <mat-icon>close</mat-icon>
              </button>
              <button mat-icon-button color="primary" ngxMatDatepickerApply>
                <mat-icon>check</mat-icon>
              </button>
            </ngx-mat-datepicker-actions>
          </ngx-mat-datetime-picker>
        </mat-form-field>
        <button
          tabindex="0"
          id="RECH-FILTRES-AVANCES"
          role="button"
          mat-button
          [ngClass]="{
            clicked: isMoreFiltersClicked || isAdvancedFiltersShowed,
            unclicked: !isMoreFiltersClicked && !isFilterResetClicked
          }"
          class="medium-screen"
          (click)="onMoreFiltersClicked()"
        >
          <i class="fa fa-filter"></i
          >{{
            isAdvancedFiltersShowed
              ? ("labels.subfolder.filters.seeLessFilters" | translate)
              : ("labels.subfolder.filters.seeMoreFilters" | translate)
          }}
        </button>
        <button
          tabindex="0"
          aria-label="reinitialiser les filtres"
          role="button"
          *ngIf="isFiltered || isStartDateChosen || isEndDateChosen"
          mat-button
          class="reset-filters-bt"
          (click)="resetFilters()"
        >
          <i class="fa fa-times"></i>
          {{ "labels.subfolder.filters.resetFilters" | translate }}
        </button>
      </div>
    </div>
    <div class="advenced-filters-area medium-screen small-screen">
      <ng-container *ngIf="isAdvancedFiltersShowed">
        <div class="advanced-filters-splitter"></div>
        <mat-form-field class="small-screen" appearance="fill">
          <mat-label tabindex="0">{{
            "labels.subfolder.filters.filterByProcess" | translate
          }}</mat-label>
          <mat-select
            [formControl]="processes"
            multiple
            (selectionChange)="onProcessSelected($event)"
            role="combobox"
          >
            <mat-option
              role="option"
              aria-checked="false"
              *ngFor="let process of processList"
              [value]="process.name"
              >{{ process.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <div
          tabindex="0"
          aria-label="zone des filtres par periode de transmission de type DatePicker"
          class="date-pickers-area"
        >
          <mat-form-field
            class="date-picker-field"
            tabindex="0"
            appearance="fill"
            id="RECH-FILTRES-DATE-DEBUT-PERIODE"
          >
            <mat-label>{{
              "labels.subfolder.filters.startTransmitionDate" | translate
            }}</mat-label>
            <input
              matInput
              [ngxMatDatetimePicker]="picker1"
              [value]="formStartDate.value"
              (keydown.enter)="getStartDate('input', $event)"
              (dateChange)="getStartDate('change', $event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker1>
              <ngx-mat-datepicker-actions>
                <button mat-icon-button ngxMatDatepickerCancel>
                  <mat-icon>close</mat-icon>
                </button>
                <button mat-icon-button color="primary" ngxMatDatepickerApply>
                  <mat-icon>check</mat-icon>
                </button>
              </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
          </mat-form-field>
          <mat-form-field
            class="date-picker-field end-date-field"
            tabindex="0"
            appearance="fill"
            id="RECH-FILTRES-DATE-FIN-PERIODE"
          >
            <mat-label>{{
              "labels.subfolder.filters.endTransmitionDate" | translate
            }}</mat-label>
            <input
              matInput
              [ngxMatDatetimePicker]="picker2"
              [value]="formEndDate.value"
              (keydown.enter)="getEndDate('input', $event)"
              (dateChange)="getEndDate('change', $event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker2>
              <ngx-mat-datepicker-actions>
                <button mat-icon-button ngxMatDatepickerCancel>
                  <mat-icon>close</mat-icon>
                </button>
                <button mat-icon-button color="primary" ngxMatDatepickerApply>
                  <mat-icon>check</mat-icon>
                </button>
              </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
      </ng-container>

      <div
        *ngIf="
          (isStartDateChosen ||
            isEndDateChosen ||
            decodedPermissions.length > 0) &&
          !isMoreFiltersClicked
        "
        class="chosen-dates"
      >
        <mat-chip-listbox tabindex="0" #chipList aria-label="periode choisie">
          <mat-chip-option
            *ngIf="decodedPermissions.length > 0"
            tabindex="0"
            aria-label="démarches choisis"
            [selectable]="selectable"
            [removable]="removable"
            class="process-chips"
            (removed)="resetProcesses()"
            id="RECH-RESUME-FILTRES"
          >
            {{ "labels.subfolder.filters.processInChips" | translate }}
            {{ decodedPermissions.join(", ") }}
            <mat-icon
              tabindex="0"
              aria-label="supprimer les démarches"
              (keydown.enter)="resetProcesses()"
              role="button"
              matChipRemove
              *ngIf="removable"
              >close</mat-icon
            >
          </mat-chip-option>

          <mat-chip-option
            tabindex="0"
            aria-label="date de debut"
            *ngIf="isStartDateChosen"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeStartDate()"
          >
            {{
              "labels.subfolder.filters.startOfTransmitionDateInChips"
                | translate
            }}
            {{ formatedStartDate }}
            <mat-icon
              tabindex="0"
              aria-label="supprimer la date de debut"
              (keydown.enter)="removeStartDate()"
              role="button"
              matChipRemove
              *ngIf="removable"
              >close</mat-icon
            >
          </mat-chip-option>
          <mat-chip-option
            tabindex="0"
            aria-label="date de fin"
            class="end-date"
            *ngIf="isEndDateChosen"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeEndDate()"
          >
            {{
              "labels.subfolder.filters.endOfTransmitionDateInChips" | translate
            }}
            {{ formatedEndDate }}
            <mat-icon
              tabindex="0"
              aria-label="supprimer la date de fin"
              (keydown.enter)="removeEndDate()"
              role="button"
              matChipRemove
              *ngIf="removable"
              >close</mat-icon
            >
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>
    <div
      class="bottom-area"
      [ngClass]="{
        'consultation-td-bottom-area': !userHasRoleSubfolderManagement()
      }"
    >
      <div class="select-all-area">
        <div
          tabindex="-1"
          *ngxPermissionsOnly="['GestionTD']"
          class="subfolder-checkbox-filter"
        >
          <mat-checkbox
            aria-label="selectionnez ce teledossier"
            tabindex="-1"
            role="checkbox"
            aria-checked="true"
            [checked]="isAllSelected && !disableCheckBox"
            (change)="selectDeselectAllToggle()"
            [disabled]="disableCheckBox"
            id="RECH-TD-BT-SELECT-ALL"
          >
            selectionnez ce teledossier
          </mat-checkbox>
        </div>
        <div>
          <div id="RECH-TD-BT-SELECT-ALL" style="display: contents">
            <span id="select-all-link" (click)="selectDeselectAllToggle()">{{
              isAllSelected
                ? ("labels.subfolder.filters.unSelectAll" | translate)
                : ("labels.subfolder.filters.selectAll" | translate)
            }}</span>
          </div>
          &nbsp;-&nbsp;
          <div id="RECH-NB-RESULTATS" style="display: contents">
            <span *ngIf="dataSource" id="total-result">
              {{ dataSource.total }}</span
            >
            <span *ngIf="dataSource && dataSource.total > 1">
              {{ "labels.subfolder.filters.resultsFinded" | translate }}
            </span>
            <span *ngIf="dataSource && dataSource.total <= 1">
              {{ "labels.subfolder.filters.resultFinded" | translate }}
            </span>
          </div>
        </div>
      </div>

      <div class="bottom-section-right-side">
        <!-- <i tabindex="0" aria-label="Changer la view des resultats en tableau" (keydown.enter)="changeView('table')" (click)="changeView('table')" class="rf-fi--xl"
      [ngClass]="{'selected-view' : isTableView, 'unselected-view': !isTableView}"
      class="fa fa-table bt-table-view"></i> -->
        <i
          tabindex="0"
          aria-label="Changer la view des resultats en liste"
          (keydown.enter)="changeView('list')"
          (click)="changeView('list')"
          class="rf-fi--xl"
          [ngClass]="{
            'selected-view': isListView,
            'unselected-view': !isListView
          }"
          class="fa fa-list-ul bt-list-view"
          id="RECH-VUES"
        ></i>
        <div class="small-screen">
          <div>
            <span *ngIf="dataSource" class="total-result">
              {{ dataSource.total }}</span
            >
            {{ "labels.subfolder.filters.resultsFinded" | translate }}
          </div>
        </div>
        <mat-form-field
          class="arrange-options"
          appearance="fill"
          id="RECH-TRIS"
        >
          <mat-label tabindex="0">{{
            "labels.subfolder.filters.sortBy" | translate
          }}</mat-label>
          <mat-select [(value)]="selected" placeholder="Mode" role="combobox">
            <mat-option
              role="option"
              *ngFor="let sortAttribute of sortAttributes"
              (keydown.enter)="onSortAttributeSelected(sortAttribute.value)"
              (click)="onSortAttributeSelected(sortAttribute.value)"
              [value]="sortAttribute.value"
            >
              {{ sortAttribute.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
