<div
  tabindex="0"
  role="alert"
  aria-labelledby="box-title"
  class="send-message-dialog-content"
>
  <div class="title-area" mat-dialog-title>
    <div class="title-right-side">
      <h5>
        <div id="CONSULT-BD-MESSAGE-TITRE" class="title">
          {{ "labels.subfolder.sendMessageDialogBox.title" | translate }}
        </div>
      </h5>
    </div>
    <i
      id="CONSULT-BD-MESSAGE-CLOSE"
      tabindex="-1"
      aria-label="annuler la mise à jour"
      class="fas fa-times"
      mat-dialog-close="cancel"
    ></i>
  </div>
  <mat-dialog-content>
    <mat-form-field class="form-input__message">
      <mat-label tabindex="0">{{
        "labels.subfolder.sendMessageDialogBox.placeholder" | translate
      }}</mat-label>
      <textarea
        id="CONSULT-BD-MESSAGE-INPUT"
        tabindex="0"
        matInput
        maxlength="500"
        name="message"
        [(ngModel)]="message"
      ></textarea>
      <input id="CONSULT-BD-MESSAGE-INPUT" matInput hidden />
    </mat-form-field>
    <div tabindex="0" class="character-limit-message">
      {{ "labels.subfolder.details.comments.charactersLimit" | translate }}
      {{ message.length }}/500
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      id="CONSULT-BD-MESSAGE-ANNULER"
      aria-label="annuler l'envoie de message"
      role="button"
      class="cancel-button"
      mat-button
      mat-dialog-close
      mat-dialog-close="cancel"
    >
      {{ "labels.subfolder.sendMessageDialogBox.cancelButton" | translate }}
    </button>
    <button
      id="CONSULT-BD-MESSAGE-BT-VALIDER"
      aria-label="confirmer l'envoie de message"
      role="button"
      class="send-button"
      mat-button
      mat-dialog-close
      mat-dialog-close="message"
      (click)="dialogRef.close(message)"
    >
      <i class="fas fa-envelope"></i>
      {{ "labels.subfolder.sendMessageDialogBox.confirmButton" | translate }}
    </button>
  </mat-dialog-actions>
</div>
