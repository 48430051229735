import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationException } from 'src/app/shared/exceptions/application.exception';
import {
  statusesList,
  Subfolder,
  SubfolderIn,
  SubfolderStatus,
} from 'src/app/shared/models/subfolder';
import { TextService } from 'src/app/shared/service/text.service';
import { ConfirmationReceptionDialogBoxComponent } from '../../modals/confirmation-dialog-box/confirmation-dialog-box.component';
import { SubfoldersService } from '../../services/subfolders.service';
import { UpdateMultiplesSubfoldersDialogBoxComponent } from '../../modals/update-multiples-subfolders-dialog-box/update-multiples-subfolders-dialog-box.component';
import {
  actionType,
  History,
  historyStatus,
} from '../../../../shared/models/history';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SubFolderResult } from '../../models/subfolder-result';
import { OAuthService } from 'angular-oauth2-oidc';
import { saveAs } from 'file-saver';
import { SubfoldersFilters } from '../../models/subfoldersFilters';
import { formatDate } from '@angular/common';
import { SubfoldersHttpService } from '../../services/subfoldersHttp.service';
import { SnackbarService } from 'src/app/shared/service/snackbar.service';
import { SubfoldersCount } from '../../models/subfoldersCount';
import { HttpErrorResponse } from '@angular/common/http';
import { PreferencesService } from 'src/app/shared/service/preferences.service';
import { SubfoldersSharedService } from '../../services/subfolders-sharedService.service';

@Component({
  selector: 'app-subfolders-list-view',
  templateUrl: './subfolders-list-view.component.html',
  styleUrls: ['./subfolders-list-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubfoldersListViewComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() dataSource: SubFolderResult;
  @Input() hasResults: boolean;
  @Input() filters: SubfoldersFilters;
  @Input() isAllSelected: boolean;

  user = '';
  @Output() refreshSubfoldersListEvent: EventEmitter<boolean> =
    new EventEmitter();
  @Output() cleanSelectionEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() isSelectionModifiedEvent: EventEmitter<boolean> =
    new EventEmitter();
  @Output() updateOnSubfolderCountNewStatus: EventEmitter<SubfoldersCount> =
    new EventEmitter();
  @Output() updateOnSubfolderCountOldStatus: EventEmitter<SubfoldersCount> =
    new EventEmitter();

  checked = false;
  subfolderIn = new SubfolderIn();
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  selectedSubfolders: Subfolder[] = [];
  oldSubfolders: Subfolder[] = [];
  isUniqueStatusSelected: boolean;
  currentStatusSelected: string;
  potentialStatuses: SubfolderStatus[] = [];
  isSendingMessageCheckboxSelected = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;
  totalSubfoldersNumber: number;
  pageSize: any;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 50, 100];
  showFirstLastButtons = true;
  updatingComment: string;
  file: any;
  closedStatus = '8';
  pendingStatus = '5';
  newStatus = 2;
  changingStatusWhenDownload = 4;
  isAllSubfoldersSelected: boolean;
  isSelectionChangeMade = false;
  allSubfolders: Subfolder[] = [];
  subfoldersToUpdate: number[];
  disabledStatusButtons: boolean;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private subfoldersService: SubfoldersService,
    private dialog: MatDialog,
    private oauthService: OAuthService,
    private subfoldersHttpService: SubfoldersHttpService,
    private snackBar: SnackbarService,
    private textService: TextService,
    private preferencesService: PreferencesService,
    private subfoldersSharedService: SubfoldersSharedService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.setPaginationIds(), 0);
  }

  isAdmNat(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims && claims.user_type === 'ADM_NAT';
  }

  ngOnInit(): void {
    this.subfoldersToUpdate = [];
    this.disabledStatusButtons = false;
    this.getUserName();
    this.pageSize = +this.preferencesService.getUserPagination();
    this.subfoldersSharedService
      .getDisableSelectAllCheckBox()
      .subscribe((value) => (this.disabled = value));
    this.subfoldersSharedService
      .getAllSubfolders()
      .subscribe((allSubfolders) => {
        this.allSubfolders = allSubfolders;
      });
  }

  private setPaginationIds() {
    const pagination = document.querySelector('#subfolders-paginator');
    if (pagination) {
      pagination
        .querySelector('.mat-mdc-paginator-navigation-previous')
        .setAttribute('id', 'RECH-PAGES-BT-PREC');
      pagination
        .querySelector('.mat-mdc-paginator-navigation-next')
        .setAttribute('id', 'RECH-PAGES-BT-SUIV');
      pagination
        .querySelector('.mat-mdc-paginator-range-label')
        .setAttribute('id', 'RECH-PAGES-NUM-TOTAL');
      pagination
        .querySelector('.mat-mdc-paginator-page-size-select')
        .setAttribute('id', 'RECH-PAGES-NUM');
      pagination
        .querySelector('.mat-mdc-paginator-page-size')
        .setAttribute('id', 'RECH-PAGES-NB-SELECT');
      pagination
        .querySelector('.mat-mdc-paginator-navigation-first')
        .setAttribute('id', 'RECH-PAGES-BT-PREMIER');
      pagination
        .querySelector('.mat-mdc-paginator-navigation-last')
        .setAttribute('id', 'RECH-PAGES-BT-DERNIER');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isAllSelected) {
      this.selectOrUnselectAllSubfolders(changes.isAllSelected.currentValue);
    }

    if (this.dataSource) {
      this.totalSubfoldersNumber = this.dataSource.total;
      if (
        this.isAllSelected ||
        (!this.isAllSelected && this.isSelectionChangeMade)
      ) {
        this.checkAllSubfolders();
      }
      if (changes.dataSource && !changes.dataSource.isFirstChange()) {
        this.refreshPaginator();
      }
    }
  }

  public onConsultButtonClicked(clickedSubfolder: Subfolder): void {
    const changingStatusForNewSubfolders = 3;
    if (+clickedSubfolder.status !== 2) {
      this.router.navigate(['/subfolders', clickedSubfolder.id]);
    } else {
      const dialogRef = this.dialog.open(
        ConfirmationReceptionDialogBoxComponent,
        {
          data: {
            isMultipleSelection: false,
            subfolder: clickedSubfolder,
            nextStatus: changingStatusForNewSubfolders,
            isAdmNat: this.isAdmNat(),
            title: this.textService.getText(
              'labels.subfolder.confirmationReceptionDialogBox.title'
            ),
            messageOfReception: this.textService.getText(
              'labels.subfolder.confirmationReceptionDialogBox.messageOfReception'
            ),
            cancelButton: this.textService.getText(
              'labels.subfolder.confirmationReceptionDialogBox.cancelButton'
            ),
            confirmButtonOfReception: this.textService.getText(
              this.isAdmNat()
                ? 'labels.subfolder.confirmationReceptionDialogBox.consultWithoutChangingSubfolderStatus'
                : 'labels.subfolder.confirmationReceptionDialogBox.confirmButtonOfReception'
            ),
          },
          autoFocus: false,
        }
      );
      dialogRef.afterClosed().subscribe({
        next: (result) => {
          if (result === 'confirm') {
            this.subfolderIn.currentStatus = +clickedSubfolder.status;
            clickedSubfolder.status = (+clickedSubfolder.status + 1).toString();
            this.subfolderIn.status = +clickedSubfolder.status;
            this.subfolderIn.comment = '';
            this.subfolderIn.message = '';
            this.subfolderIn.author = this.user;
            this.subfolderIn.messageTransmission = true;
            this.updateSubfolderStatus(clickedSubfolder.id, this.subfolderIn);
          } else if (result === 'confirm-no-update') {
            this.router.navigate(['/subfolders', clickedSubfolder.id]);
          }
        },
        complete: () => {
          this.disapearById(clickedSubfolder.id);
        },
      });
    }
  }

  public disapearById(id: number): void {
    let IdToHIde = document.getElementById(id.toString());
    IdToHIde.style.display = 'none';
  }
  /**
   * Appeler le service pour mettre à jour le sous dossier.
   *
   * @param subfolderId - Subfolder id to update
   * @param subfolderIn - Subfolder data to update
   */
  public updateSubfolderStatus(
    subfolderId: number,
    subfolderIn: SubfolderIn
  ): void {
    this.subfoldersToUpdate.push(subfolderId);
    this.subfoldersHttpService
      .updateStatus(subfolderId, subfolderIn, null)
      .subscribe({
        next: (subfolder: Subfolder) => {
          this.router.navigate(['/subfolders', subfolderId]);
          this.snackBar.updateStatusSuccess();
          this.removeSubfoldersId(subfolderId);
        },
        error: (error: HttpErrorResponse) => {
          this.snackBar.handleStatusUpdateError(error);
          this.removeSubfoldersId(subfolderId);
        },
      });
  }

  removeSubfoldersId(subfolderId: number): void {
    const index: number = this.subfoldersToUpdate.indexOf(subfolderId);
    if (index !== -1) {
      this.subfoldersToUpdate.splice(index, 1);
    }
  }

  removeSubfoldersIds(subfolderIds: number[]): void {
    subfolderIds.forEach((subfolderId: number) =>
      this.removeSubfoldersId(subfolderId)
    );
  }

  public onDownloadButtonClicked(clickedSubfolder: Subfolder): void {
    const changingStatusWhenDownload = 4;
    if (+clickedSubfolder.status > 3) {
      this.downloadSubfolder(clickedSubfolder, false);
    } else {
      const dialogRef = this.dialog.open(
        ConfirmationReceptionDialogBoxComponent,
        {
          data: {
            isMultipleSelection: false,
            subfolder: clickedSubfolder,
            nextStatus: changingStatusWhenDownload,
            title: this.textService.getText(
              'labels.subfolder.confirmationDownloadDialogBox.title'
            ),
            messageOfReception: this.textService.getText(
              'labels.subfolder.confirmationDownloadDialogBox.messageOfReception'
            ),
            cancelButton: this.textService.getText(
              'labels.subfolder.confirmationDownloadDialogBox.cancelButton'
            ),
            confirmButtonOfReception: this.textService.getText(
              'labels.subfolder.confirmationDownloadDialogBox.confirmButtonOfReception'
            ),
          },
          autoFocus: false,
        }
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'confirm') {
          this.subfolderIn.currentStatus = +clickedSubfolder.status;
          this.subfolderIn.status = changingStatusWhenDownload;
          this.subfolderIn.comment = '';
          this.subfolderIn.message = '';
          this.subfolderIn.author = this.user;
          this.subfolderIn.messageTransmission = true;
          this.downloadSubfolder(clickedSubfolder, true);
        }
      });
    }
  }

  public downloadAllSelectedSubfolders(): void {
    let isChangingStatus = false;
    const changingStatusWhenDownload = 4;
    let selectedSubfolder;
    const selectedSubfoldersIdListForUpdate: number[] = [];
    const selectedSubfoldersIdListForDownload: number[] = [];
    this.selectedSubfolders.forEach((subfolder) => {
      if (+subfolder.status < changingStatusWhenDownload) {
        isChangingStatus = true;
        selectedSubfoldersIdListForUpdate.push(subfolder.id);
      }
      selectedSubfolder = subfolder;
      selectedSubfoldersIdListForDownload.push(subfolder.id);
    });
    if (!isChangingStatus) {
      this.downloadMultipleSubfolders(
        selectedSubfoldersIdListForDownload,
        [],
        changingStatusWhenDownload
      );
      this.oldSubfolders = this.selectedSubfolders;
      this.cleanSelection();
    } else {
      const dialogRef = this.dialog.open(
        ConfirmationReceptionDialogBoxComponent,
        {
          data: {
            isMultipleSelection: true,
            subfolder: selectedSubfolder,
            nextStatus: changingStatusWhenDownload,
            title: this.textService.getText(
              'labels.subfolder.confirmationDownloadAllDialogBox.title'
            ),
            messageOfReception: this.textService.getText(
              'labels.subfolder.confirmationDownloadAllDialogBox.messageOfReception'
            ),
            cancelButton: this.textService.getText(
              'labels.subfolder.confirmationDownloadAllDialogBox.cancelButton'
            ),
            confirmButtonOfReception: this.textService.getText(
              'labels.subfolder.confirmationDownloadAllDialogBox.confirmButtonOfReception'
            ),
          },
          autoFocus: false,
        }
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'confirm') {
          this.downloadMultipleSubfolders(
            selectedSubfoldersIdListForDownload,
            selectedSubfoldersIdListForUpdate,
            changingStatusWhenDownload
          );
          this.oldSubfolders = this.selectedSubfolders;
          this.cleanSelection();
        }
      });
    }
  }

  private goToNewStatusPage(newStatus: number): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        status: newStatus,
        pageNumber: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  public onSubfolderSelected(subfolder): void {
    this.isSelectionChangeMade = true;
    this.isSelectionModifiedEvent.emit();
    this.selectedSubfolders.forEach((sub) => {
      if (sub.id === subfolder.id) {
        this.selectedSubfolders = this.selectedSubfolders.filter(
          (item) => item !== sub
        );
      }
    });
    if (subfolder.isSelected) {
      this.selectedSubfolders.push(subfolder);
    } else {
      this.selectedSubfolders = this.selectedSubfolders.filter(
        (item) => item !== subfolder
      );
    }
    this.checkStatusOnSelectedMultiplesSubfolders(this.selectedSubfolders);
  }

  public checkStatusOnSelectedMultiplesSubfolders(
    selectedSubfolders: Subfolder[]
  ): void {
    if (selectedSubfolders && selectedSubfolders.length > 0) {
      const isAllSameStatuses = (subfolder) =>
        selectedSubfolders[0].status === subfolder.status;
      this.isUniqueStatusSelected = selectedSubfolders.every(isAllSameStatuses);
      if (this.isUniqueStatusSelected) {
        this.getStatusListForMultipleSelectionTreatment(
          +selectedSubfolders[0].status
        );
      }
    }
  }

  public getStatusListForMultipleSelectionTreatment(
    currentStatus: number
  ): void {
    this.potentialStatuses = [];
    statusesList.forEach((status) => {
      if (
        status.id > currentStatus &&
        status.id < +this.closedStatus &&
        status.id !== +this.pendingStatus
      ) {
        this.potentialStatuses.push(status);
      }
    });
  }

  /**
   * Renvoie true si parmi la liste des télédossiers
   * téléchargés il y a au moins un télédossiers clos
   */
  public disableDownloadButton(): boolean {
    return this.selectedSubfolders.some((subfolder) => subfolder.status == '8');
  }

  public mofifySelectedSubfoldersStatuses(
    modifyingStatus: SubfolderStatus
  ): void {
    const prevStatus = this.selectedSubfolders[0].status;
    const subfoldersIdList: number[] = [];
    this.isSendingMessageCheckboxSelected = false;
    this.updatingComment = '';
    this.selectedSubfolders.forEach((subfolder) => {
      subfoldersIdList.push(subfolder.id);
    });
    const dialogRef = this.dialog.open(
      UpdateMultiplesSubfoldersDialogBoxComponent,
      {
        width: '50%',
        data: {
          prevStatus,
          nextStatus: modifyingStatus.id,
          title: this.textService.getText(
            'labels.subfolder.confirmationUpdateStatusDialogBox.title'
          ),
          messageOfReception: this.textService.getText(
            'labels.subfolder.confirmationUpdateStatusDialogBox.message'
          ),
          cancelButton: this.textService.getText(
            'labels.subfolder.confirmationUpdateStatusDialogBox.cancelButton'
          ),
          confirmButtonOfReception: this.textService.getText(
            'labels.subfolder.confirmationUpdateStatusDialogBox.confirmButton'
          ),
          hasComment: true,
          updatingComment: this.updatingComment,
          isCheckboxSelected: this.isSendingMessageCheckboxSelected,
        },
        autoFocus: false,
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== 'cancel') {
        this.disabledStatusButtons = true;
        this.dataSource.subfolders.forEach((subfolder) => {
          subfolder.isSelected = false;
        });
        this.selectedSubfolders.forEach((subfolder) => {
          this.subfoldersToUpdate.push(subfolder.id);
        });
        this.subfoldersHttpService
          .updateMultipleSubfolders(
            modifyingStatus.id,
            subfoldersIdList,
            result.comment,
            this.user,
            this.filters,
            +prevStatus
          )
          .subscribe({
            next: (finalResult: SubFolderResult) => {
              this.dataSource.subfolders = finalResult.subfolders;
              this.removeSubfoldersIds(subfoldersIdList);
              this.disabledStatusButtons = false;
              this.sendNewSubfolderCount(
                modifyingStatus.id,
                subfoldersIdList.length,
                true
              );
              this.sendNewSubfolderCount(
                +prevStatus,
                subfoldersIdList.length,
                false
              );
              this.goToNewStatusPage(modifyingStatus.id);
            },
            error: (error: HttpErrorResponse) => {
              this.removeSubfoldersIds(subfoldersIdList);
              this.disabledStatusButtons = false;
              this.snackBar.handleStatusUpdateError(error);
            },
          });
        this.oldSubfolders = this.selectedSubfolders;
        this.cleanSelection();
      }
    });
  }

  /**
   * Sends specific status and number of new subfolders included in this status.
   *
   * @param updatedStatus - Status on which subfolders were updated to.
   * @param numberOfUpdatedSubfolders - Number of subfolders that were updated to this new status.
   */
  private sendNewSubfolderCount(
    updatedStatus: number,
    numberOfUpdatedSubfolders: number,
    isNewStatus: boolean
  ): void {
    const newSubfolderCount: SubfoldersCount = {
      status: updatedStatus,
      count: numberOfUpdatedSubfolders,
    };
    if (isNewStatus) {
      this.updateOnSubfolderCountNewStatus.emit(newSubfolderCount);
    } else {
      this.updateOnSubfolderCountOldStatus.emit(newSubfolderCount);
    }
  }

  public cleanSelection(): void {
    this.cleanSelectionEvent.emit();
    this.selectedSubfolders = [];
    this.potentialStatuses = [];
    this.dataSource.subfolders.forEach((subfolder) => {
      subfolder.isSelected = false;
    });
  }

  /**
   * appeler le service pour poster l'objet newHistory
   * @param newHistory l'objet history
   */
  public async addHistoryEvent(
    newHistory: History,
    subfolderId: number
  ): Promise<void> {
    await this.subfoldersService
      .historyUploadService(newHistory, subfolderId)
      .then((subResult: History) => {
        return subResult;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public selectOrUnselectAllSubfolders(isAllSubfoldersSelected: boolean): void {
    if (isAllSubfoldersSelected) {
      this.oldSubfolders = this.selectedSubfolders;
      this.selectedSubfolders = [];
      this.subfoldersSharedService
        .getAllSubfolders()
        .subscribe((allSubfolders) => {
          this.selectedSubfolders = allSubfolders;
          this.isSelectionChangeMade = false;
          this.checkAllSubfolders();
          this.checkStatusOnSelectedMultiplesSubfolders(
            this.selectedSubfolders
          );
        });
    } else {
      if (!this.isSelectionChangeMade) {
        this.uncheckAllSubfolders();
      }
      this.checkStatusOnSelectedMultiplesSubfolders(this.selectedSubfolders);
    }
  }

  public checkAllSubfolders(): void {
    this.dataSource.subfolders.forEach((subfolder) => {
      this.selectedSubfolders.forEach((sub) => {
        if (sub.id === subfolder.id) {
          subfolder.isSelected = true;
        }
      });
    });
  }
  public uncheckAllSubfolders(): void {
    this.selectedSubfolders = [];

    this.dataSource.subfolders.forEach((subfolder) => {
      this.subfoldersSharedService
        .getAllSubfolders()
        .subscribe((allSubfolders) => {
          allSubfolders.forEach((sub) => {
            if (sub.id !== subfolder.id) {
              subfolder.isSelected = false;
            }
          });
        });
    });
  }

  public handlePageEvent(event: PageEvent): void {
    this.totalSubfoldersNumber = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        pageNumber: this.pageIndex,
        pageSize: this.pageSize,
      },
      queryParamsHandling: 'merge',
    });
    if (event.previousPageIndex) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Downloads the chosen subfolder and update the status if necessary.
   *
   * @param clickedSubfolder - Subfolder from which files should be downloaded from.
   * @param shouldUpdateSubfolder - Boolean to confirm whether or not the subfolder status should be updated.
   */
  public downloadSubfolder(
    clickedSubfolder: Subfolder,
    shouldUpdateSubfolder: boolean
  ): void {
    const newHistory: History = {
      id: undefined,
      subfolderId: clickedSubfolder.id,
      updateTimestamp: new Date(),
      author: this.user,
      actionType: actionType.ATTACH_DL_ALL,
      messageTransmission: false,
      message: '',
      subfolderCurrentStatus: +clickedSubfolder.status,
      status: historyStatus.SENT,
    };

    this.subfoldersToUpdate.push(clickedSubfolder.id);
    this.subfoldersHttpService
      .downloadAllAttachments(
        Number(clickedSubfolder.id),
        newHistory,
        this.preferencesService.getUserIncludeXML()
      )
      .subscribe({
        next: (res: Blob) => {
          this.removeSubfoldersId(clickedSubfolder.id);
          this.file = res;
          const date: string = formatDate(
            new Date(),
            'yyyyMMdd-HH.mm',
            'fr-FR'
          );
          saveAs(
            this.file,
            date + '_' + clickedSubfolder.subfolderNumber + '.zip'
          );
          this.snackBar.openSnackbar(
            'labels.subfolder.listView.snackbar.downloadSuccess'
          );
          if (shouldUpdateSubfolder) {
            this.updateStatusOnDownload(clickedSubfolder);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.removeSubfoldersId(clickedSubfolder.id);
          this.snackBar.downloadFail();
          throw new ApplicationException(
            error.message,
            error.status.toString(),
            error.error,
            error.name
          );
        },
      });
  }

  public userHasRoleSubfolderManagement(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();

    if (!claims) {
      return false;
    }

    return claims.groups.indexOf('GestionTD') !== -1;
  }

  private updateStatusOnDownload(clickedSubfolder: Subfolder): void {
    if (+clickedSubfolder.status < this.changingStatusWhenDownload) {
      this.subfoldersHttpService
        .updateStatus(clickedSubfolder.id, this.subfolderIn, null)
        .subscribe({
          next: (subfolder: Subfolder) => {
            this.snackBar.updateStatusSuccess();
            this.goToNewStatusPage(this.changingStatusWhenDownload);
          },
          error: (error: HttpErrorResponse) =>
            this.snackBar.handleStatusUpdateError(error),
        });
    }
  }

  /**
   * Downloads all attachments from multiple subfolders.
   *
   * @param subfoldersIds - ids of the selected subfolders to download
   * @param selectedSubfoldersIdListForUpdate -  ids of the selected subfolders to update status
   * @param changingStatusWhenDownload - new status to put on subfolders
   */
  public downloadMultipleSubfolders(
    subfoldersIds: number[],
    selectedSubfoldersIdListForUpdate: number[],
    changingStatusWhenDownload: number
  ): void {
    const newHistory: History = {
      id: undefined,
      subfolderId: undefined,
      updateTimestamp: new Date(),
      author: this.user,
      actionType: actionType.ATTACH_DL_ALL,
      messageTransmission: false,
      message: '',
      subfolderCurrentStatus: undefined,
      status: historyStatus.SENT,
      subfolderList: subfoldersIds,
    };

    subfoldersIds.forEach((subfolderId) =>
      this.subfoldersToUpdate.push(subfolderId)
    );
    selectedSubfoldersIdListForUpdate.forEach((subfolderId) =>
      this.subfoldersToUpdate.push(subfolderId)
    );

    let isDownloadedWithSuccess = false;
    this.subfoldersHttpService
      .downloadAllAttachmentsMultipleSubfolders(
        newHistory,
        this.preferencesService.getUserIncludeXML()
      )
      .subscribe({
        next: (res: Blob) => {
          this.removeSubfoldersIds(subfoldersIds);
          this.removeSubfoldersIds(selectedSubfoldersIdListForUpdate);
          this.snackBar.openSnackbar(
            'labels.subfolder.listView.snackbar.downloadAllSuccess'
          );
          this.file = res;
          const date: string = formatDate(
            new Date(),
            'yyyyMMdd-HH.mm',
            'fr-FR'
          );
          const zipName: string = date + '_portailHubEE.zip';
          saveAs(this.file, zipName);
          isDownloadedWithSuccess = true;
          this.updateStatusAfterDownload(
            isDownloadedWithSuccess,
            selectedSubfoldersIdListForUpdate,
            changingStatusWhenDownload
          );
        },
        error: (error: HttpErrorResponse) => {
          this.removeSubfoldersIds(subfoldersIds);
          this.removeSubfoldersIds(selectedSubfoldersIdListForUpdate);
          this.snackBar.downloadFail();
          throw new ApplicationException(
            error.message,
            error.status.toString(),
            error,
            error.name
          );
        },
      });
  }

  private updateStatusAfterDownload(
    isDownloadedWithSuccess: boolean,
    selectedSubfoldersIdListForUpdate: number[],
    changingStatusWhenDownload: number
  ): void {
    if (
      isDownloadedWithSuccess &&
      selectedSubfoldersIdListForUpdate.length > 0
    ) {
      this.subfoldersHttpService
        .updateMultipleSubfolders(
          changingStatusWhenDownload,
          selectedSubfoldersIdListForUpdate,
          '',
          this.user,
          this.filters,
          +parseInt(this.oldSubfolders[0].status)
        )
        .subscribe({
          next: (response: SubFolderResult) => {
            this.dataSource.subfolders = response.subfolders;
            this.goToNewStatusPage(changingStatusWhenDownload);
          },
          error: (error: HttpErrorResponse) => {
            this.snackBar.handleStatusUpdateError(error);
          },
        });
    }
  }

  /**
   * Refresh Paginator to display correct pageIndex.
   * Used when switching between different subfolders status.
   */
  refreshPaginator(): void {
    if (this.paginator) {
      this.paginator.pageIndex = this.pageIndex;
      this.route.queryParamMap.subscribe((params: ParamMap) => {
        if (this.paginator) {
          this.paginator.page.emit({
            length: this.totalSubfoldersNumber,
            pageIndex: +params.get('pageNumber'),
            pageSize: +params.get('pageSize')
              ? +params.get('pageSize')
              : +this.preferencesService.getUserPagination(),
          });
        }
      });
    }
  }

  private getUserName(): void {
    const claims: any = this.oauthService.getIdentityClaims();
    if (claims) {
      this.user = `${
        claims.given_name.charAt(0).toUpperCase() + claims.given_name.slice(1)
      } ${claims.family_name.toUpperCase()}`;
    }
  }
}
