import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-get-all-subfolders-dialog',
  templateUrl: './get-all-subfolders.component.html',
  styleUrls: ['./get-all-subfolders.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GetAllSubFolders {
  constructor(
    public dialogRef: MatDialogRef<GetAllSubFolders>,
    private translate: TranslateService
  ) {}

  cancel(): void {
    this.dialogRef.close('cancel');
  }
}
