export interface Attachment {
  id: number;
  middlewareID?: string;
  fileName: string;
  type: string;
  size: number;
  formattedSize?: string;
  mimeType: string;
  subfolderId?: number;
  creationTimestamp?: Date;
  historyId?: number;
  status?: number;
  icon?: string;
  isNew?: boolean;
}

export enum attStatus {
  ACCEPTED = 0,
  REJECTED = 1,
  PENDING = 2,
}
