import { Attachment } from './attachment';
import { History } from './history';
import { Organization } from './organization';
import { Process } from './process';
import { Applicant } from './applicant';

//TODO swap status type from string to number
export interface Subfolder {
  id: number;
  middlewareID?: string;
  process: Process;
  applicant: Applicant;
  subfolderNumber: string;
  recipientOrganization: Organization;
  status: string;
  transmissionTimestamp: Date;
  updateTimestamp?: Date;
  submitteddate?: string;
  submittedtime?: string;
  updatedate?: string;
  updatetime?: string;
  lastUpdateAuthor?: string;
  isSelected?: boolean;
  history?: History[];
  attachments?: Attachment[];
  purgedBinary?: boolean;
  purgeDateTime?: Date;
}

export class SubfolderIn {
  status: number;
  comment: string;
  message: string;
  subfoldersIds: number[];
  author: string;
  messageTransmission: boolean;
  currentStatus: number;
  attachements?: File[];
}

export interface SubfolderStatus {
  id: number;
  statusName: string;
  statusNamePlural: string;
  statusIcon: string;
  actionName: string;
}

export const statusesList: SubfolderStatus[] = [
  {
    id: 0,
    statusName: 'Tous les télédossiers',
    statusNamePlural: 'Tous les télédossiers',
    statusIcon: 'fa fa-file',
    actionName: '',
  },
  {
    id: 2,
    statusName: 'Nouveau',
    statusNamePlural: 'Nouveaux',
    statusIcon: 'fa fa-exclamation',
    actionName: '',
  },
  {
    id: 3,
    statusName: 'Reçu',
    statusNamePlural: 'Reçus',
    statusIcon: 'fa fa-download',
    actionName: 'Accuser réception',
  },
  {
    id: 4,
    statusName: 'En cours',
    statusNamePlural: 'En cours',
    statusIcon: 'fas fa-file-signature',
    actionName: 'Commencer le traitement',
  },
  {
    id: 5,
    statusName: 'En attente de compléments',
    statusNamePlural: 'En attente de compléments',
    statusIcon: 'fas fa-undo-alt',
    actionName: 'Demander des compléments',
  },
  {
    id: 6,
    statusName: 'Refusé',
    statusNamePlural: 'Refusés',
    statusIcon: 'fa fa-times',
    actionName: 'Refuser',
  },
  {
    id: 7,
    statusName: 'Traité',
    statusNamePlural: 'Traités',
    statusIcon: 'fa fa-check',
    actionName: 'Finaliser le traitement',
  },
  {
    id: 8,
    statusName: 'Clos',
    statusNamePlural: 'Clos',
    statusIcon: 'fa fa-file-archive',
    actionName: '',
  },
];
