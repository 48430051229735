<section
  tabindex="0"
  aria-label="section pieces jointes"
  class="p-4 pb-5 pieces-jointes"
>
  <div
    class="col-12 d-flex flex-column align-items-center"
    *ngIf="disableButton(false)"
  >
    {{
      "labels.subfolder.details.attachments.FolderCloseNoDownloadButton"
        | translate: { date: getPurgeDate() }
    }}
  </div>
  <h2 id="CONSULT-TD-LIBELLE-PJS" class="text-uppercase font-weight-bold">
    {{ "labels.subfolder.details.attachments.title" | translate }}
  </h2>

  <div class="container-fluid">
    <div class="row" *ngIf="!isUploading">
      <div class="col-12 col-md-9 container-fluid mb-4">
        <app-subfolders-details-attachment-list
          *ngIf="subfolder?.attachments"
          [attachments]="subfolder.attachments"
          (downloadFile)="downloadFile.emit($event)"
          [isAdminMode]="false"
          [shouldDisableDownload]="disableButton(false)"
        >
        </app-subfolders-details-attachment-list>
      </div>

      <div class="col-12 col-md-3 d-flex flex-column align-items-center">
        <div
          id="CONSULT-TD-TELECH_PJS"
          class="tooltip-info"
          matTooltip=" {{
            'labels.subfolder.details.attachments.downloadToolTip' | translate
          }}"
          matTooltipPosition="above"
          [matTooltipDisabled]="userHasRoleSubfolderManagement()"
        >
          <button
            *ngIf="!disableButton(false) && !isAdmNat()"
            aria-label="télécharger le télédossier"
            role="button"
            (click)="downloadAll()"
            mat-stroked-button
            id="downloadButton"
            [disabled]="!userHasRoleSubfolderManagement()"
          >
            <i
              id="CONSULT-TD-IC-DLD"
              class="fa fa-download fa-lg"
              aria-hidden="true"
            ></i>
            <span class="button-label">
              {{ "labels.subfolder.details.attachments.download" | translate }}
            </span>
          </button>
        </div>
        <div
          *ngIf="isUploadAllowedByProcess()"
          id="CONSULT-TD-DEPOS_PJ"
          class="tooltip-info"
          matTooltip=" {{
            'labels.subfolder.details.header.disabledUploadDocuments'
              | translate
          }}"
          matTooltipPosition="left"
          [matTooltipDisabled]="displayUploadButton()"
        >
          <button
            *ngxPermissionsOnly="['GestionTD']"
            aria-label="charger un fichier"
            role="button"
            type="button"
            mat-stroked-button
            (click)="openUploadZone()"
            id="uploadButton"
            [disabled]="!displayUploadButton()"
          >
            <i
              id="CONSULT-TD-IC-UPLOAD"
              class="fa fa-file-upload fa-lg"
              aria-hidden="true"
            ></i>
            <span class="button-label">
              {{ "labels.subfolder.details.attachments.upload" | translate }}
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isUploading">
      <div class="col-12">
        <app-upload-files-zone
          [subfolder]="subfolder"
          (CancelUploadButtonClickedEvent)="cancelUpload()"
          (ConfirmUploadButtonClickedEvent)="upload($event)"
        >
        </app-upload-files-zone>
      </div>
    </div>
  </div>
</section>
