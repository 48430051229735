<div class="row pt-4">
  <div
    *ngFor="let attachment of attachments"
    class="col-12 col-sm-6 col-lg-3 d-flex flex-column align-items-center"
  >
    <button
      role="listitem"
      tabindex="0"
      title="filedownload"
      aria-label="télécharger le fichier suivant"
      (keydown.enter)="downloadFile.emit(attachment)"
      (click)="downloadFile.emit(attachment)"
      [disabled]="
        isAdminMode ||
        !userHasRoleSubfolderManagement() ||
        shouldDisableDownload ||
        isAdmNat()
      "
      class="d-flex flex-column align-items-center"
    >
      <div id="CONSULT-TD-LOGO_FORMAT_PJ">
        <fa-icon
          [icon]="attachment.mimeType | fileIcon"
          [class]="attachment.historyId ? 'uploadFromPortail' : ''"
        ></fa-icon>
        <i *ngIf="attachment.isNew" class="fas fa-exclamation-circle"></i>
      </div>

      <span id="CONSULT-TD-NOM_PJ" tabindex="0" class="mt-3">{{
        attachment.fileName
      }}</span>
      <span id="CONSULT-TD-DIM_PJ">{{ attachment.size | fileSize }}</span>
    </button>
  </div>
</div>
