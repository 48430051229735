import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment_endpoints } from '../../../../environments/environment';
import { Subfolder } from '../../../shared/models/subfolder';
import { History } from '../../../shared/models/history';
import { ParamMap, Router } from '@angular/router';
import { SubfoldersFilters } from '../models/subfoldersFilters';
import { SubFolderResult } from '../models/subfolder-result';
import { OAuthService } from 'angular-oauth2-oidc';
import { SubfoldersCount } from '../models/subfoldersCount';
import { AbstractService } from './abstract.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubfoldersService extends AbstractService {
  private token = '';
  private headers: HttpHeaders;
  private urlSubfolders = environment_endpoints.api.subfolders;
  private urlHistory = environment_endpoints.api.history;

  /**
   * Construteur du service
   *
   * @param httpClient client utilisé pour exécuter des requêtes
   */
  constructor(
    private httpClient: HttpClient,
    private oauthService: OAuthService,
    private router: Router
  ) {
    super();
    this.token = this.oauthService.getIdToken();
    this.headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + this.token)
      .append('AccessToken', this.oauthService.getAccessToken());
  }

  /**
   * Récupère la liste des sous dossiers
   */
  public getSubFolders(): Promise<void | Subfolder[]> {
    return this.httpClient
      .get<Subfolder[]>(this.urlSubfolders, { headers: this.headers })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Récupère la liste des sous dossiers par query params
   */
  // TODO : replace "url" with "filter" object and send it to back to get results ;
  // si "filters.searchedSubfolder" existe, faire le traitelent de recherche entre le nom/prenom/numeto td
  // TODO : POST function with "filter" object
  public getSubFoldersByFilters(url: string): Promise<void | SubFolderResult> {
    return this.httpClient
      .get<SubFolderResult>(`${this.urlSubfolders}?${url}`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  public getSubFoldersByFiltersAsObservable(
    url: string
  ): Observable<void | SubFolderResult> {
    return this.httpClient.get<SubFolderResult>(
      `${this.urlSubfolders}?${url}`,
      {
        headers: this.headers,
      }
    );
  }

  /**
   * Ajouter un nouvel historique dans la BD
   * @param history l'objet history
   */
  public historyUploadService(
    history: History,
    id: number
  ): Promise<void | History> {
    return this.httpClient
      .post<History>(`${this.urlHistory}/${id}`, history, {
        headers: this.headers,
      })
      .toPromise()
      .then((response: History) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Récupère un sous dossier via son id
   */
  public getSubFolderById(id: number): Promise<void | Subfolder> {
    return this.httpClient
      .get<Subfolder>(`${this.urlSubfolders}/${id}`, { headers: this.headers })
      .toPromise()
      .then((response: Subfolder) => {
        return response;
      })
      .catch((error) => {
        this.router.navigate(['/404error']);
        this.handleError(error);
      });
  }

  /**
   * Récupère la liste des status des sous dossiers
   */
  public getSubFoldersProcessesList(): Promise<void | string[]> {
    return this.httpClient
      .get<string[]>(this.urlSubfolders, { headers: this.headers })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Récupère la pieces jointe par idAttachment associées à un sous dossier via son id
   */
  public getAttachmentByAttachmentIdSubFolderId(
    id: number,
    idAtt: string,
    history: History
  ): Promise<void | Blob> {
    return this.httpClient
      .post<Blob>(`${this.urlSubfolders}/${id}/attachments/${idAtt}`, history, {
        headers: this.headers,
        responseType: 'blob' as 'json',
      }) // TODO : replace with api url
      .toPromise()
      .then((response: Blob) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Retourne le nombre de subfolders
   */
  public getSubfoldersCount(): Promise<void | SubfoldersCount[]> {
    return this.httpClient
      .get<SubfoldersCount[]>(`${this.urlSubfolders}/count`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Récupère l'historique associées à sous dossier via son id
   */
  public getHistoryBySubFolderId(id: number): Promise<void | History[]> {
    return this.httpClient
      .get<History[]>(`${this.urlHistory}?subfolderId=${id}`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response: History[]) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Récuperer les filtres depuis l'URL
   */
  public getFilters(params: ParamMap): SubfoldersFilters {
    const filters: SubfoldersFilters = new SubfoldersFilters();
    filters.pageSize = +params.get('pageSize');
    filters.pageNumber = +params.get('pageNumber');
    filters.status = params.get('status');
    filters.startSubmittedDate = params.get('startSubmittedDate');
    filters.requester = params.get('requester');
    filters.searchedSubfolder = params.get('searchedSubfolder');
    filters.number = +params.get('number');
    filters.processes = params.get('process');
    filters.endSubmittedDate = params.get('endSubmittedDate');
    filters.sortAttribute = params.get('sortAttribute');
    filters.direction = params.get('direction');
    return filters;
  }

  /**
   * Post the new attachments and history associated
   *
   * @param subfolderId - Id of the subfolder
   * @param history - History element describing the action
   * @param files - Array of File to be uploaded
   */
  public uploadAttachments(
    subfolderId: number,
    history: History,
    files: File[]
  ): Promise<void | History> {
    const body: FormData = new FormData();
    body.append(
      'history',
      new Blob([JSON.stringify(history)], { type: 'application/json' })
    );
    files.forEach((element) => {
      body.append('files', element);
    });
    return this.httpClient
      .post<History>(
        `${this.urlSubfolders}/${subfolderId}/attachments/upload`,
        body,
        { headers: this.headers }
      )
      .toPromise()
      .then((response: History) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Format subfolder number according to RECH-RA-18 (SFD §7.4.1.6).
   *
   * @param subfolderNumber - Subfolder number to format
   * @returns Formatted subfolder number
   */
  public formatSubfolderNumber(subfolderNumber: string): string {
    const numbers = subfolderNumber.split('-');
    let displayedSubfolderNumber = null;

    /* Algorithm optimization by evaluating condition first then looping */
    /* Check if subfolder number was initially formated correctly :
     * If TD was incorrectly formatted, display whole subfolder number
     * Otherwise display subfolder number formatted as written in SFD
     */
    if (numbers.length < 3) {
      displayedSubfolderNumber = numbers[0];
      for (let i = 1; i < numbers.length; i++) {
        displayedSubfolderNumber = displayedSubfolderNumber.concat('-');
        displayedSubfolderNumber = displayedSubfolderNumber.concat(numbers[i]);
      }
    } else {
      // Retrieve after OSL and process numbers
      displayedSubfolderNumber = numbers[2];
      for (let i = 3; i < numbers.length; i++) {
        displayedSubfolderNumber = displayedSubfolderNumber.concat('-');
        displayedSubfolderNumber = displayedSubfolderNumber.concat(numbers[i]);
      }
    }
    return displayedSubfolderNumber;
  }
}
