import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attachment } from '../../../../../shared/models/attachment';
import { MatDialog } from '@angular/material/dialog';
import { CancelUpdateDialogBoxComponent } from '../../../modals/cancel-update-dialog-box/cancel-update-dialog-box.component';
import { Subfolder } from 'src/app/shared/models/subfolder';
import { SnackbarService } from 'src/app/shared/service/snackbar.service';
import { TextService } from 'src/app/shared/service/text.service';

@Component({
  selector: 'app-upload-files-zone',
  templateUrl: './upload-files-zone.component.html',
  styleUrls: ['./upload-files-zone.component.scss'],
})
export class UploadFilesZoneComponent implements OnInit {
  @Input() subfolder: Subfolder;
  @Input() isUploadingFromUpdatingBox;
  @Output() CancelUploadButtonClickedEvent: EventEmitter<boolean> =
    new EventEmitter();
  @Output() ConfirmUploadButtonClickedEvent: EventEmitter<any> =
    new EventEmitter();

  attachments: Attachment[] = [];
  files: any = [];
  isFormatAccepted = false;
  attachmentsFormatsEnabled: string;
  attachmentsMaxSizeEnabled: number;
  constructor(
    public dialog: MatDialog,
    private snackbar: SnackbarService,
    private textService: TextService
  ) {}

  ngOnInit(): void {
    this.attachmentsFormatsEnabled = this.setEnabledAttachmentsFormats(
      this.subfolder
    );
    this.attachmentsMaxSizeEnabled =
      this.subfolder.process.attachmentsMaxSizeEnabled;
  }

  /**
   * Retrieves all attachments format accepted by this subfolder process.
   *
   * @param subfolder - Subfolder on which file upload will be done.
   * @returns a string with all attachments format accepted
   */
  public setEnabledAttachmentsFormats(subfolder: Subfolder): string {
    const enabledFormats = [];
    const regexp = new RegExp('[A-z]+/', 'g');
    subfolder.process.attachmentsFormatsEnabled.forEach((format) => {
      format = format.replace(regexp, '').toUpperCase();
      enabledFormats.push(format);
    });
    return enabledFormats.join(', ');
  }

  public openConfirmationCancelingDialog(): void {
    const dialogRef = this.dialog.open(CancelUpdateDialogBoxComponent, {
      width: '50%',
      data: {
        cancelTitle: this.textService.getText(
          'labels.subfolder.details.attachments.cancelUploadDialog'
        ),
        cancelButton: this.textService.getText(
          'labels.subfolder.details.attachments.noCancelUpload'
        ),
        confirmCancelButton: this.textService.getText(
          'labels.subfolder.details.attachments.ok'
        ),
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== 'cancel') {
        this.onCancelUploadButtonClicked();
      }
    });
  }

  public uploadFile(event): void {
    for (let index = 0; index < event.length; index++) {
      if (event[index].type !== '') {
        const a: Attachment = {
          id: index,
          fileName: event[index].name,
          mimeType: event[index].type,
          size: event[index].size,
          type: '',
        };
        this.attachments.push(a);
        this.files.push(event[index]);
      } else {
        this.snackbar.openSnackbar(
          'labels.subfolder.details.attachments.snackbar.uploadFailedInvalidFormat'
        );
      }
    }
  }

  public deleteAttachment(index: number): void {
    this.files.splice(index, 1);
    this.attachments.splice(index, 1);
  }

  public isAttachmentsFormatsAccepted(): boolean {
    let res = this.attachments.every((attachment) => {
      let mimeType = attachment.mimeType;
      if (mimeType === 'text/xml') mimeType = 'application/xml';
      return this.subfolder.process.attachmentsFormatsEnabled.includes(
        mimeType
      );
    });
    return res;
  }

  public isAttachmentSizeAccepted(): boolean {
    const isSizeAccepted = (attachment) =>
      attachment.size <= this.attachmentsMaxSizeEnabled;
    return this.attachments.every((attachment) => {
      return isSizeAccepted(attachment);
    });
  }

  public onCancelUploadButtonClicked(): void {
    if (this.attachments.length > 0 || this.attachments.length === 0) {
      this.CancelUploadButtonClickedEvent.emit();
    }
  }
  public onConfirmUploadButtonClicked(): void {
    if (this.attachments.length > 0) {
      this.ConfirmUploadButtonClickedEvent.emit(this.files);
    } else {
      this.CancelUploadButtonClickedEvent.emit();
    }
  }
}
