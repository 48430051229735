<div
  tabindex="0"
  role="alert"
  aria-labelledby="box-title"
  class="update-multiples-status-content"
>
  <div class="title-area" mat-dialog-title>
    <div class="title-right-side">
      <div>
        <i
          tabindex="-1"
          aria-label="annuler la mise à jour"
          role="button"
          class="fas fa-exclamation-triangle"
        ></i>
      </div>
      <h5>
        <div id="box-title" class="title">{{ data.title }}</div>
      </h5>
    </div>
    <i class="fas fa-times" mat-dialog-close="cancel"></i>
  </div>
  <mat-dialog-content>
    <div
      tabindex="0"
      aria-label="résumé du mise à jour des statuts"
      class="changing-status-summary"
    >
      {{ "labels.subfolder.confirmationReceptionDialogBox.from" | translate }}
      <div tabindex="0" aria-label="statut actuel" class="old-status">
        <i
          class="{{ 'subfolder.statusIcon.' + data.prevStatus | translate }}"
        ></i>
        <div tabindex="0" class="status-name">
          {{ "subfolder.status." + data.prevStatus | translate }}
        </div>
      </div>
      {{ "labels.subfolder.confirmationReceptionDialogBox.to" | translate }}
      <div tabindex="0" aria-label="statut mise à jour" class="new-status">
        <i
          class="{{ 'subfolder.statusIcon.' + data.nextStatus | translate }}"
        ></i>
        <div tabindex="0" class="status-name">
          {{ "subfolder.status." + data.nextStatus | translate }}
        </div>
      </div>
    </div>
    <div class="col-12 d-flex flex-column px-2 pt-4">
      <mat-form-field>
        <mat-label
          tabindex="0"
          aria-label="ajouter un commentaire à ce traitement"
          >{{
            "labels.subfolder.details.comments.placeholder" | translate
          }}</mat-label
        >
        <textarea
          tabindex="0"
          matInput
          [maxlength]="500"
          name="comment"
          [(ngModel)]="data.comment"
        ></textarea>
        <input matInput hidden />
      </mat-form-field>
      <div tabindex="0" class="character-limit text-right">
        {{ "labels.subfolder.details.comments.charactersLimit" | translate }}
        {{ data.comment?.length }}/500
      </div>
    </div>
    <div tabindex="0" class="update-text">
      {{ "labels.subfolder.updateMessage1" | translate }}
    </div>
    <div tabindex="0" class="update-text">
      {{ "labels.subfolder.updateMessage2" | translate }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      aria-label="annuler la mise à jour"
      role="button"
      class="cancel-button"
      mat-button
      mat-dialog-close
      mat-dialog-close="cancel"
    >
      {{ data.cancelButton }}
    </button>
    <button
      aria-label="confirmer la mise à jour"
      role="button"
      class="confirm-button"
      mat-button
      [mat-dialog-close]="data.isCheckboxSelected"
      mat-dialog-close="confirm"
      (click)="dialogRef.close(data)"
    >
      {{ data.confirmButtonOfReception }}
    </button>
  </mat-dialog-actions>
</div>
