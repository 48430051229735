import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(size: number): string {
    const byteUnit = [' Ko', ' Mo', ' Go'];
    let i = -1;
    let tempSize: number = size;
    if (tempSize) {
      do {
        tempSize = tempSize / 1000;
        i++;
      } while (tempSize > 1000);
      tempSize = Math.round((tempSize + Number.EPSILON) * 100) / 100;
    }
    return tempSize ? tempSize + byteUnit[i] : null;
  }
}
