<div
  tabindex="0"
  aria-label="filtrer par le statut de traitement des télédossiers"
  class="subfolders-tabs-container"
  id="rech-filtre-statut"
>
  <ng-container *ngFor="let statusTab of statuses; let statusIndex = index">
    <div
      tabindex="0"
      (keydown.enter)="onTabClicked(statusTab.id)"
      (click)="onTabClicked(statusTab.id)"
      [ngClass]="{
        'before-active-status':
          statusIndex + 1 <= urlStatusId && urlStatusId !== 0,
        'all-statuts-tab': statusIndex === 0,
        'active-status': statusTab.id == urlStatusId
      }"
      class="tab-status-content"
      matTooltip="Télédossiers au statut {{ statusTab.statusName }}"
      matTooltipPosition="above"
      [matTooltipDisabled]="statusIndex === 0"
    >
      <div class="icon-content">
        <div class="fake-invisible-icon" *ngIf="statusIndex === 0"></div>
        <div
          [ngClass]="{ 'all-statuts': statusIndex === 0 }"
          class="life-line-icon"
        >
          <i class="{{ statusTab.statusIcon }}"></i>
        </div>
      </div>
      <div class="life-line-content">
        <div
          *ngIf="statusIndex > 1"
          [ngClass]="{
            'dotted-line-left': statusIndex === 4 || statusIndex === 5,
            'life-line-left': statusIndex !== 4
          }"
        ></div>

        <div
          *ngIf="statusIndex != 0 && statusIndex != 7"
          [ngClass]="{
            'dotted-line-right': statusIndex === 3 || statusIndex === 4,
            'life-line-right': statusIndex !== 3,
            'active-life-line-right': statusIndex + 1 == urlStatusId
          }"
        ></div>
      </div>
      <div
        aria-label="statut selectionné"
        class="label-content"
        [ngClass]="{ 'all-statuts-tab': statusIndex === 0 }"
      >
        <div
          class="tab-label"
          id="tabLabel"
          [ngClass]="{ 'active-status-label': statusIndex + 1 == urlStatusId }"
        >
          {{ statusTab.statusNamePlural
          }}<span *ngIf="statusIndex > 0">
            ({{ getNumberOfResultsByStatus(statusTab.id) }})</span
          >
        </div>
      </div>
    </div>
  </ng-container>
</div>
