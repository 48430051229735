<section
  tabindex="0"
  aria-label="section commentaire"
  class="section__comment p-4 pb-5"
>
  <h2 id="CONSULT-TD-LIBELEE-COMMENTAIRE" class="text-uppercase font-weight-bold">
    {{ "labels.subfolder.details.comments.title" | translate }}
  </h2>

  <div class="container-fluid">
    <div class="row mt-4">
      <div id="CONSULT-TD-COMMENTAIRE" class="col-12 col-lg-9 d-flex flex-column px-0">
        <mat-form-field>
          <mat-label
            tabindex="0"
            aria-label="ajoutez un commentaire de maximum 500 caracteres"
            >{{
              "labels.subfolder.details.comments.placeholder" | translate
            }}</mat-label
          >
          <textarea
            tabindex="0"
            matInput
            [maxlength]="500"
            name="comment"
            [(ngModel)]="comment"
          ></textarea>
        </mat-form-field>
        <div tabindex="0" class="character-limit text-right">
          {{ "labels.subfolder.details.comments.charactersLimit" | translate }}
          {{ comment?.length }}/500
        </div>
      </div>

      <div id="CONSULT-TD-AJOUT_COMM"
        class="
          col-12 col-lg-3
          d-flex
          flex-column
          align-items-center
          justify-content-center
        "
      >
        <button
          aria-label="ajouter un commentaire"
          role="button"
          mat-stroked-button
          (click)="sendComment(comment)"
        >
          <i id="CONSULT-TD-IC-MESSAGE" class="fa fa-comment-alt fa-lg" aria-hidden="true"></i
          ><span>{{
            "labels.subfolder.details.comments.addComment" | translate
          }}</span>
        </button>
      </div>
    </div>
  </div>
</section>
